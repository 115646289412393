import { makeAutoObservable } from 'mobx'

import { globalErrorsStore } from 'store/GlobalErrorsStore'

class LoginStore {
  accessToken: null | string = null

  isFromAdminDashboard = false

  constructor() {
    makeAutoObservable(this)
    this.rehydrateInfo()
  }

  login = (accessToken: null | string, isFromAdminDashboard?: 'isFromAdminDashboard') => {
    this.accessToken = accessToken
    this.isFromAdminDashboard = !!isFromAdminDashboard
    this.storeInfo()
  }

  logout = () => {
    this.accessToken = null
    this.isFromAdminDashboard = false
    this.storeInfo()
  }

  error = () => {
    globalErrorsStore.addError('Incorrect username/password')
    this.logout()
  }

  storeInfo = () => {
    localStorage.setItem('pmAccessToken', JSON.stringify(this.accessToken))
    localStorage.setItem('pmIsFromAdminDashboard', JSON.stringify(this.isFromAdminDashboard))
  }

  rehydrateInfo = () => {
    this.accessToken = JSON.parse(localStorage.getItem('pmAccessToken') as string)
    this.isFromAdminDashboard = JSON.parse(localStorage.getItem('pmIsFromAdminDashboard') as string) ?? false
  }
}

export const loginStore = new LoginStore()
