import { Divider, Form, Input, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Body } from 'src/layout'

import { RestorePasswordActionResponse } from 'api/base/api'
import { restorePasswordApi } from 'api/restorePasswordApi'

import {
  RESET_PASSWORD_EXPIRED_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
  RESET_PASSWORD_UNKNOWN_TOKEN_PATH,
} from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'

import 'pages/resetpassword/restore.scss'

const { ResultEnum } = RestorePasswordActionResponse

export const ResetPassword: React.FC = () => {
  const [form] = Form.useForm()
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [unknownToken, setUnknownToken] = useState(false)
  const [expiredToken, setExpiredToken] = useState(false)
  const [spinning, setSpinning] = useState(false)

  const sendRestorePasswordRequest = () => {
    setSpinning(true)
    restorePasswordApi
      .restorePasswordAction({
        password: { value: password },
        token: new URLSearchParams(window.location.search).get('resetToken') as string,
      })
      .then((response) => {
        setSpinning(false)

        if (response.result === ResultEnum.Success) {
          setSuccess(true)
          return
        }

        if (response.result === ResultEnum.UnknownToken) {
          setUnknownToken(true)
          return
        }

        if (response.result === ResultEnum.ExpiredToken) {
          setExpiredToken(true)
        }
      })
  }

  const handleSubmit = () => {
    sendRestorePasswordRequest()
  }

  return success ? (
    <Redirect to={RESET_PASSWORD_SUCCESS_PATH} />
  ) : unknownToken ? (
    <Redirect to={RESET_PASSWORD_UNKNOWN_TOKEN_PATH} />
  ) : expiredToken ? (
    <Redirect to={RESET_PASSWORD_EXPIRED_PATH} />
  ) : (
    <Body>
      <div className="reset-password">
        {/* //TODO: add error */}
        <div className="reset-password__header_title">
          <Title level={4}>Reset Password</Title>
        </div>

        <Divider />

        <div>Please enter new password you&apos;ll be using to Login to your account.</div>
        <br />

        <div className="reset-password__content">
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your new password',
                },
              ]}
            >
              <Input.Password placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>

            <Form.Item className="reset-password__footer">
              <Spin spinning={spinning}>
                <CustomButton type="primary">Reset</CustomButton>
              </Spin>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Body>
  )
}
