import { CheckOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import { AdUnitDictBean, BundleBean } from 'api/base/api'

import { ADUNIT_LIST_PATH } from 'constants/routes'

import { UserContext } from 'utils/userContext.tsx'

import { CustomButton } from 'components/customButton/CustomButton'

import { DeleteButtonPopConfirm } from 'pages/adunit/DeleteButtonPopConfirm'

import 'pages/adunit/adUnitForm.scss'

interface Props {
  sites: BundleBean[]
  adUnitBean?: AdUnitDictBean
  showArchiveButton?: boolean
  handleSubmit: (form: any) => void
  handleDelete?: (e: any) => void
  submitButtonName: string
}

const { Option } = Select

export const AdUnitForm: React.FC<Props> = ({
  sites,
  adUnitBean,
  showArchiveButton,
  handleSubmit,
  handleDelete,
  submitButtonName,
}) => {
  const { fullAccess } = useContext(UserContext)
  const history = useHistory()

  const [form] = useForm()
  const formLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
      lg: { span: 4 },
      xl: { span: 3 },
      xxl: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 14 },
      lg: { span: 16 },
      xl: { span: 17 },
      xxl: { span: 18 },
    },
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [adUnitBean])

  return (
    <div className="config-adunitdict-form__form">
      <Form
        form={form}
        {...formLayout}
        initialValues={{
          siteId: adUnitBean?.siteId,
          adUnitCode: adUnitBean?.adUnitCode,
          adUnitName: adUnitBean?.adUnitName,
        }}
        onFinish={(form) => handleSubmit(form)}
      >
        <Form.Item
          name="siteId"
          label="Site"
          rules={[
            {
              required: true,
              message: 'Please Select Site',
            },
          ]}
        >
          <Select showSearch allowClear placeholder="Select Site">
            {sites.length > 0 && <Option value="__all">Select all</Option>}
            {sites.map((item: BundleBean) => (
              <Option key={item.id} value={item.id as number}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="adUnitCode"
          label="Ad Unit Code"
          style={{ marginBottom: 16 }}
          rules={[
            {
              required: true,
              message: 'Please enter Ad Unit Code',
            },
          ]}
        >
          <Input placeholder="Ad Unit Code" />
        </Form.Item>

        <Form.Item
          name="adUnitName"
          label="Ad Unit Name"
          style={{ marginBottom: 16 }}
          rules={[
            {
              required: true,
              message: 'Please enter Ad Unit Name',
            },
          ]}
        >
          <Input placeholder="Ad Unit Name" />
        </Form.Item>

        <Row align="stretch">
          <Col xs={12} sm={6} lg={4} xl={3} xxl={2} />
          <Col span={8}>
            <Space>
              <CustomButton disabled={!fullAccess} type={fullAccess ? 'primary' : 'disabled'} icon={<CheckOutlined />}>
                {submitButtonName}
              </CustomButton>
              <CustomButton type="bordered" onClick={() => history.push(ADUNIT_LIST_PATH)}>
                Cancel
              </CustomButton>
            </Space>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {showArchiveButton && fullAccess && (
              <Space>
                <DeleteButtonPopConfirm handleDelete={handleDelete as (e: any) => void} id={adUnitBean?.id as number} />
              </Space>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}
