import * as React from 'react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'

import { accountApi } from 'api/accountApi'
import { RegistrationForm, UIXValueString } from 'api/base/api'
import { registrationApi } from 'api/registrationApi'

import { PROFILE_PATH } from 'constants/routes'

import { ProgressScreen } from 'layout/index'

import { RegistrationFormContainer } from 'pages/registration/RegistrationFormContainer'

export const Registration: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('Registration error')
  const [showError, setShowError] = useState(false)
  const [isRegistrationFromWebsite, setIsRegistrationFromWebsite] = useState(false)

  const location = useLocation()

  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [registrationInProgress, setRegistrationInProgress] = useState(false)

  const doRegister = (form: RegistrationForm) => {
    setRegistrationInProgress(true)
    registrationApi.create(form).then(
      (response) => {
        if (response.success) {
          accountApi.login(String(form.email), String(form.password)).then(() => setRegistrationSuccess(true))
        } else {
          setShowError(true)
          setErrorMessage(response.form?.email.errorMessage as string)
        }
        setRegistrationInProgress(false)
      },
      (error) => {
        setErrorMessage(error)
        setShowError(true)
        setRegistrationInProgress(false)
      },
    )
  }

  const onFinish = (form: RegistrationForm) => {
    const registrationBean = {
      email: form.email,
      password: form.password,
      billingPlan: { value: form.billingPlan } as UIXValueString,
      needHelp: { value: false },
      referralCode: form.referralCode,
      valid: true,
    }
    doRegister(registrationBean)
  }

  const registerFromWebsite = async (urlParams: any) => {
    const email = urlParams.get('email')
    const password = urlParams.get('password')
    const billingPlan = 'cpm'
    const needHelp = false

    if (email && password) {
      await doRegister({
        email,
        password,
        billingPlan: { value: billingPlan },
        needHelp,
        referralCode: {},
      } as any)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)

    if (urlParams.get('email')) {
      setIsRegistrationFromWebsite(true)
      registerFromWebsite(urlParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {}, [registrationSuccess])

  if (isRegistrationFromWebsite && !showError) {
    return <ProgressScreen />
  }

  if (registrationSuccess) {
    return <Redirect to={PROFILE_PATH} />
  }

  return (
    <RegistrationFormContainer
      onFinish={onFinish}
      registrationInProgress={registrationInProgress}
      showError={showError}
      errorMessage={errorMessage}
    />
  )
}
