import { EditOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { Checkbox, Col, Divider, Input, Row, Spin, Table, Tooltip } from 'antd'
import Column from 'antd/lib/table/Column'
import { SorterResult } from 'antd/lib/table/interface'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { BundleBean, ListResponseBundleBean, SiteHealth } from 'api/base/api'
import { ListFilters, siteApi } from 'api/siteApi'

import { CustomButton } from 'components/customButton/CustomButton'
import { Error } from 'components/globalError'

import 'pages/config/configList.scss'

import HealthStateEnum = SiteHealth.HealthStateEnum

export const SiteList: React.FC = () => {
  const location = useLocation()

  const [inProgress, setInProgress] = useState(true)

  const [listResponseOfBundle, setListResponseOfBundle] = useState<ListResponseBundleBean | null>(null)

  const [showError, setShowError] = useState(false)

  const [listFilters, setListFilters] = useState<ListFilters>({
    name: undefined,
    domain: undefined,
    status: [],
    order: 'ascend',
    orderBy: undefined,
  } as ListFilters)

  const [pageData, setPageData] = useState<{ page: number; pageSize: number }>({ page: 1, pageSize: 15 })

  const updateFilters = (newFilters: ListFilters) => {
    setPageData({ page: 1, pageSize: 15 })
    setListFilters(newFilters)
  }

  useEffect(() => {
    const loadData = (page: number, pageSize: number) => {
      siteApi.getSiteList(listFilters, page, pageSize).then(
        (resp) => {
          if (resp) {
            setListResponseOfBundle(resp)
          }
          setInProgress(false)
          setShowError(false)
        },
        () => {
          setShowError(true)
        },
      )
    }
    loadData(pageData.page, pageData.pageSize)
  }, [location, listFilters, pageData])

  const [statusesTemp, setStatusesTemp] = useState(() => ({ notStarted: false, ok: false, bad: false }))
  const getStatuses = () => {
    const statuses = []
    if (statusesTemp.notStarted) {
      statuses.push(HealthStateEnum.NotStarted.toString())
    }
    if (statusesTemp.ok) {
      statuses.push(HealthStateEnum.Ok.toString())
    }
    if (statusesTemp.bad) {
      statuses.push(HealthStateEnum.Bad.toString())
    }
    return statuses
  }
  const getStatusFilterDropdown =
    () =>
    ({ confirm, clearFilters }: any) => (
      <Col>
        <Row>
          <Checkbox
            style={{ marginLeft: 10, marginTop: 8 }}
            name="No Analytics"
            checked={statusesTemp.notStarted}
            onChange={() => setStatusesTemp({ ...statusesTemp, notStarted: !statusesTemp.notStarted })}
          />
          <span style={{ marginTop: 8 }}>No Analytics</span>
        </Row>

        <Row>
          <Checkbox
            style={{ marginLeft: 10 }}
            name="Active"
            checked={statusesTemp.ok}
            onChange={() => setStatusesTemp({ ...statusesTemp, ok: !statusesTemp.ok })}
          />
          <span>Active</span>
        </Row>

        <Row>
          <Checkbox
            style={{ marginLeft: 10 }}
            name="Inactive"
            checked={statusesTemp.bad}
            onChange={() => setStatusesTemp({ ...statusesTemp, bad: !statusesTemp.bad })}
          />
          <span>Inactive</span>
        </Row>
        <Row>
          <CustomButton
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, margin: 8 }}
            onClick={() => {
              updateFilters({ ...listFilters, status: getStatuses() })
              confirm()
            }}
          >
            Ok
          </CustomButton>
          <CustomButton
            type="bordered"
            size="small"
            style={{ width: 90, margin: 8 }}
            onClick={() => {
              setStatusesTemp({ ok: false, bad: false, notStarted: false })
              updateFilters({ ...listFilters, status: [] })
              clearFilters()
              confirm()
            }}
          >
            Reset
          </CustomButton>
        </Row>
      </Col>
    )

  const getFilterDropdown =
    (dataName: string, setFilterValue: (value: string | undefined) => void) =>
    ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            const value = selectedKeys?.length > 0 ? selectedKeys[0] : undefined
            confirm()
            setFilterValue(value)
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <CustomButton
          type="primary"
          onClick={() => {
            const value = selectedKeys?.length > 0 ? selectedKeys[0] : undefined
            confirm()
            setFilterValue(value)
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </CustomButton>
        <CustomButton
          type="bordered"
          onClick={() => {
            clearFilters()
            confirm()
            setPageData({ page: 1, pageSize: 15 })
            setFilterValue(undefined)
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </CustomButton>
      </div>
    )

  const sortChange = (sorter: SorterResult<any> | SorterResult<any>[]) => {
    const { order, column } = sorter as SorterResult<any>
    let orderedField: 'name' | 'domain' | 'status' | 'lastStatsTime' | undefined
    if (['name', 'domain', 'status', 'lastStatsTime'].some((el) => column?.dataIndex === el)) {
      orderedField = column?.dataIndex?.toString() as 'name' | 'domain' | 'status' | 'lastStatsTime'
    }

    updateFilters({
      ...listFilters,
      orderBy: orderedField,
      order: order === 'descend' ? 'descend' : 'ascend',
    })
  }

  const dataSource = listResponseOfBundle?.data.map((item: any, index: number) => ({
    key: index,
    ...item.data,
    status:
      item.data.siteHealth.healthState !== HealthStateEnum.NotStarted ? (
        item.data.siteHealth.healthState === HealthStateEnum.Bad &&
        item.data.siteHealth.trafficHoursAgo &&
        item.data.siteHealth.trafficHoursAgo > 0 ? (
          <Tooltip title={`We do not receive analytics events for ${item.data.siteHealth.trafficHoursAgo} hours.`}>
            <span style={{ color: 'gray' }}>Inactive</span>
          </Tooltip>
        ) : (
          <Tooltip title="Site is working">
            <span style={{ color: 'limegreen' }}>Active</span>
          </Tooltip>
        )
      ) : (
        <Tooltip title={"We get no analytics from this site. Please check the site's setup"}>
          <span style={{ color: 'red' }}>No Analytics</span>
        </Tooltip>
      ),
  }))

  return (
    <>
      <Error
        showError={showError}
        message={`Sites load error. 
    Please refresh the page or try again later. 
    If the problem persists, please contact Customer Support.`}
        closable
        isAuthorised
      />

      <div className="config-list">
        <div className="config-list__page">
          <Spin spinning={inProgress}>
            <Table
              dataSource={dataSource}
              className="config-list__page_table"
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                position: ['bottomLeft'],
                showSizeChanger: true,
                pageSizeOptions: [15, 30, 50, 100],
                defaultPageSize: 15,
                pageSize: pageData.pageSize,
                onChange: (page, pageSize) => setPageData({ page, pageSize }),
                current: pageData.page,
                total: listResponseOfBundle?.pagination.total,
              }}
              onChange={(_1, _2, sorter, extra) => extra.action === 'sort' && sortChange(sorter)}
              size="small"
              scroll={{ x: 'max-content' }}
            >
              <Column
                key="name"
                title="Name"
                dataIndex="name"
                sorter
                filterDropdown={getFilterDropdown('name', (value) => updateFilters({ ...listFilters, name: value }))}
              />
              <Column key="bundleKey" title="ID" dataIndex="bundleKey" sorter />
              <Column
                key="domain"
                title="Domain"
                dataIndex="domain"
                sorter
                filterDropdown={getFilterDropdown('domain', (value) =>
                  updateFilters({ ...listFilters, domain: value }),
                )}
              />
              <Column
                key="status"
                title="Status"
                dataIndex="status"
                filterDropdown={getStatusFilterDropdown()}
                sorter
              />

              <Column key="lastStatsTime" title="Last Stats Time" dataIndex="lastStatsTime" sorter />
              <Column
                key="action"
                title="Actions"
                render={(_: string, record: BundleBean) => (
                  <span>
                    <Link to={`/config/${record.id}/edit/update`}>
                      <CustomButton type="shadow" icon={<EditOutlined />}>
                        Edit
                      </CustomButton>
                    </Link>
                    <Divider className="actions-horisontable-divider" type="vertical" />
                    <Link to={`/config/${record.id}/edit/setup`}>
                      <CustomButton type="shadow" icon={<SettingOutlined />}>
                        Analytics Setup
                      </CustomButton>
                    </Link>
                  </span>
                )}
              />
            </Table>
          </Spin>
        </div>
      </div>
    </>
  )
}
