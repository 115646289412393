import { AlertNotificationBean, AlertNotificationControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class AlertNotificationApi {
  api = new AlertNotificationControllerApi(configuration, configuration.basePath, customFetch)

  create(form: AlertNotificationBean) {
    return this.api.createAlertNotification(form)
  }

  update(id: number, bean: AlertNotificationBean) {
    return this.api.updateAlertNotification(bean, id)
  }

  delete(id: number) {
    return this.api.deleteAlertNotification(id)
  }

  getOne(id: number) {
    return this.api.getAlertNotification(id)
  }

  getAlertNotificationList() {
    return this.api.getAlertNotificationList()
  }
}

export const alertNotificationApi = new AlertNotificationApi()
