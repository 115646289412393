import { Alert, Spin, Table } from 'antd'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { accountApi } from 'api/accountApi'
import { PaymentsHistoryBean } from 'api/base/api'

import { TERMS_OF_SERVICE_PATH } from 'constants/routes'

import { Error } from 'components/globalError'

import 'pages/payments/payments.scss'

export const PaymentsScreen: React.FC = () => {
  const [inProgress, setInProgress] = useState(true)

  const [paymentsHistoryBean, setPaymentsHistoryBean] = useState<PaymentsHistoryBean | null>(null)

  const errorMessage = `Payments load error. 
    Please refresh the page or try again later. 
    If the problem persists, please contact Customer Support.`

  const [showError, setShowError] = useState(false)

  const loadPayments = () => {
    accountApi.loadPayments().then(
      (resp) => {
        if (resp) {
          setPaymentsHistoryBean(resp)
        }
        setInProgress(false)
        setShowError(false)
      },
      () => {
        setShowError(true)
      },
    )
  }

  const inTrialMessage = (
    <div>
      <h4>Trial Period</h4>
      You are on a Free Trial period now. Your Free Trial expiration date is {paymentsHistoryBean?.trialEndDate}. You
      won&apos;t be charged if you cancel before the trial period end. To cancel, please click Block Account button at
      the bottom of the Profile page. Your analytics will be stopped.
    </div>
  )

  const billingStartInfo = (
    <span>
      Your billing started on {paymentsHistoryBean?.billingDate as string}. You can see the complete payment terms and
      conditions on the <NavLink to={TERMS_OF_SERVICE_PATH}>Terms of Service</NavLink> page.
    </span>
  )

  const colNames = [
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
    },
    {
      key: 'paymentDate',
      title: 'Payment Date',
      dataIndex: 'paymentDate',
    },
    {
      key: 'billingDate',
      title: 'Billing Date',
      dataIndex: 'billingDate',
    },
    {
      key: 'period',
      title: 'Period',
      dataIndex: 'period',
    },
    {
      key: 'requests',
      title: 'Requests',
      dataIndex: 'requests',
    },
    {
      key: 'imprs',
      title: 'Impressions',
      dataIndex: 'imprs',
    },
    {
      key: 'chargeAmount',
      title: 'Charge in USD',
      dataIndex: 'chargeAmount',
    },
  ]

  const dataSource = paymentsHistoryBean?.transactions.map((item: any, index: number) => {
    const period = `${item.billStartDate} - ${item.billEndDate}`
    return { key: index, period, ...item }
  })

  useEffect(loadPayments, [])

  return (
    <>
      <Error showError={showError} message={errorMessage} closable isAuthorised />

      <div className="payments">
        <div className="payments__page">
          <Spin spinning={inProgress}>
            {paymentsHistoryBean?.inTrial ? (
              <Alert className="payments__page_alert" type="warning" showIcon message={inTrialMessage} />
            ) : (
              <>
                <Alert type="info" showIcon className="payments__page_alert" message={billingStartInfo} />

                {paymentsHistoryBean?.transactions.length ? (
                  <Table columns={colNames} dataSource={dataSource} className="payments__page_table" />
                ) : (
                  <p>No payments yet</p>
                )}
              </>
            )}
          </Spin>
        </div>
      </div>
    </>
  )
}
