import { message, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { adUnitApi } from 'api/adUnitApi'
import { AdUnitDictBean, BundleBean } from 'api/base/api'
import { siteApi } from 'api/siteApi.ts'

import { ADUNIT_LIST_PATH } from 'constants/routes'

import { Error } from 'components/globalError'

import { AdUnitForm } from 'pages/adunit/AdUnitForm'

import 'pages/adunit/adUnitDictPage.scss'

interface Props {
  id?: number
}

export const AdUnitPage: React.FC<Props> = ({ id }) => {
  const [adUnitBean, setAdUnitBean] = useState<AdUnitDictBean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [sites, setSites] = useState<BundleBean[]>([])

  const [errorMessage, setErrorMessage] = useState(
    `Ad Unit Load Error. 
        Please refresh the page or try again later. 
        If the problem persists, please contact Customer Support.`,
  )

  const loadAdUnitBean = (id: number) => {
    setIsLoading(true)
    adUnitApi.getAdUnitDict(id).then(
      (response) => {
        setAdUnitBean(response.data)
        setIsLoading(false)
        setShowError(false)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage('Ad Unit load error')
      },
    )
  }

  const loadInfo = () => {
    setIsLoading(true)
    siteApi.getAllByAccount().then(
      (response) => {
        setSites(response)
        setIsLoading(false)
        setShowError(false)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage('Info load error')
      },
    )
  }

  const history = useHistory()

  const createAdUnit = (form: any) => {
    adUnitApi
      .createAdUnitDict(form)
      .then(() => {
        message.success('Ad Unit added')
        window.prebidmanagerUiLibFiltersData = null
        history.push(ADUNIT_LIST_PATH)
      })
      .catch((err) => {
        setErrorMessage(err)
        setShowError(true)
      })
  }

  const updateAdUnit = (form: AdUnitDictBean) => {
    const updateForm: AdUnitDictBean = {
      siteId: form.siteId as number,
      adUnitCode: form.adUnitCode as string,
      adUnitName: form.adUnitName as string,
    }

    adUnitApi.updateAdUnitDict(id as number, updateForm).then(
      () => {
        setIsLoading(false)
        message.success('AdUnit changes saved')
        window.prebidmanagerUiLibFiltersData = null
        history.push(ADUNIT_LIST_PATH)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage(
          `AdUnit update failed. 
                    Please try again. 
                    If the problem persists, please contact Customer Support.`,
        )
      },
    )
  }
  const handleDelete = (id: number) => {
    adUnitApi.delete(id).then(() => {
      message.warn('Ad Unit is deleted')
      window.prebidmanagerUiLibFiltersData = null
      history.push(ADUNIT_LIST_PATH)
    })
  }

  useEffect(() => {
    loadInfo()
    if (id) {
      loadAdUnitBean(id)
    }
  }, [id])

  return (
    <div className="adunitdict-page">
      <Error showError={showError} message={errorMessage} closable isAuthorised />
      <div className="adunitdict-page__main">
        <Spin spinning={isLoading}>
          <div className="config-adunitdict-form__title">
            {id ? (
              <Title level={4}>Edit {adUnitBean?.adUnitName}</Title>
            ) : (
              <Title level={4}>Add New Ad Unit Name</Title>
            )}
          </div>
          {!id ? (
            <AdUnitForm sites={sites} submitButtonName="Add Ad Unit Name" handleSubmit={createAdUnit} />
          ) : (
            <AdUnitForm
              sites={sites}
              adUnitBean={adUnitBean as AdUnitDictBean}
              submitButtonName="Save"
              showArchiveButton
              handleSubmit={updateAdUnit}
              handleDelete={handleDelete}
            />
          )}
        </Spin>
      </div>
    </div>
  )
}
