import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import * as React from 'react'

import { CustomButton } from 'components/customButton/CustomButton'

interface Props {
  id: number
  handleArchive: (e: any) => void
}

const popconfirmText = 'Are you sure you want to archive this site?'

export const ArchiveButtonPopConfirm: React.FC<Props> = ({ id, handleArchive }) => (
  <Popconfirm
    title={popconfirmText}
    okText="Yes"
    cancelText="No"
    onConfirm={() => {
      handleArchive(id)
    }}
  >
    <Tooltip title="Stop collecting statistics for the site. Previous statistics will not be deleted.">
      <CustomButton type="warning" icon={<DeleteOutlined />}>
        Archive
      </CustomButton>
    </Tooltip>
  </Popconfirm>
)
