import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'

import { ALERT_CONFIG_PATH, ALERT_NOTIFICATION_PATH } from 'constants/routes'

import { AlertNotificationPage } from 'pages/alertConfig/AlertNotificationPage'

export const AlertNotificationIndexComponent = () => (
  <Switch>
    <Route path={`${ALERT_NOTIFICATION_PATH}/create`} render={() => <AlertNotificationPage />} />
    <Route
      path={`${ALERT_NOTIFICATION_PATH}/:id/edit/:key`}
      render={(rp) => <AlertNotificationPage id={+(rp.match.params.id as string)} />}
    />
    <Redirect from="/" to={`${ALERT_CONFIG_PATH}/create`} />
  </Switch>
)
