import { WarningOutlined } from '@ant-design/icons'
import { Divider, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useState } from 'react'

import { RESTORE_PATH } from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'

import 'pages/registration/registration.scss'

export const EmailConfirmationUnknownCodeScreen: React.FC = () => {
  const [spinning, setSpinning] = useState(false)

  return (
    <div className="registration">
      <div className="registration_header__title">
        <Title level={4}>
          Registration <WarningOutlined />
        </Title>
        <Divider />
      </div>

      <div className="registration_content">
        <div className="registration_content">
          <p>
            Email confirmation code is unknown. Please use the link you got in the Password Reset email or request a new
            link.
          </p>
        </div>

        <div className="registration_footer">
          <Spin spinning={spinning}>
            <CustomButton type="primary" linkTo={RESTORE_PATH} onClick={() => setSpinning(true)}>
              Restore password
            </CustomButton>
          </Spin>
        </div>
      </div>
    </div>
  )
}
