import { CheckOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import { AlertConfigBean, AlertNotificationBean } from 'api/base/api'

import { ALERT_PATH } from 'constants/routes'

import { UserContext } from 'utils/userContext.tsx'

import { CustomButton } from 'components/customButton/CustomButton'

import { DeleteButtonPopConfirm } from 'pages/alertConfig/DeleteButtonPopConfirm'

import 'pages/alertConfig/alertForm.scss'

const { Option } = Select

interface Props {
  alertConfigs: AlertConfigBean[]
  alertNotificationBean?: AlertNotificationBean
  showArchiveButton?: boolean
  handleSubmit: (form: any) => void
  handleDelete?: (e: any) => void
  submitButtonName: string
}

export const AlertNotificationForm: React.FC<Props> = ({
  alertConfigs,
  alertNotificationBean,
  showArchiveButton,
  handleSubmit,
  handleDelete,
  submitButtonName,
}) => {
  const { fullAccess } = useContext(UserContext)
  const history = useHistory()

  const [form] = useForm()
  const formLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
      lg: { span: 4 },
      xl: { span: 3 },
      xxl: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 14 },
      lg: { span: 16 },
      xl: { span: 17 },
      xxl: { span: 18 },
    },
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [alertNotificationBean])

  const handleSelectAll = (value: any) => {
    if (value && value.length && value.includes('__all')) {
      if (value.length === alertConfigs.length + 1) {
        return []
      }
      return [...alertConfigs.map((el: AlertConfigBean) => el.id)]
    }
    return value
  }

  return (
    <div className="config-alert-form__form">
      <Form
        form={form}
        {...formLayout}
        initialValues={{
          name: alertNotificationBean?.name,
          alertConfigIds: alertNotificationBean?.alertConfigIds,
          emails: alertNotificationBean?.emails,
          active: alertNotificationBean?.active,
        }}
        onFinish={(form) => handleSubmit(form)}
      >
        <Form.Item
          name="name"
          label="Notification Name"
          style={{ marginBottom: 16 }}
          rules={[
            {
              required: true,
              message: 'Please enter Alert Notification Name',
            },
          ]}
        >
          <Input placeholder="Alert Notification Name" />
        </Form.Item>

        <Form.Item name="active" label="Status" initialValue>
          <Select defaultValue>
            <Option key="true" value>
              Active
            </Option>
            <Option key="false" value={false}>
              Hold
            </Option>
          </Select>
        </Form.Item>

        <Form.Item name="alertConfigIds" label="Alerts" getValueFromEvent={handleSelectAll} initialValue={[]}>
          <Select showSearch allowClear mode="multiple" placeholder="All Available">
            {alertConfigs && alertConfigs.length > 0 && <Option value="__all">Select all</Option>}
            {alertConfigs &&
              alertConfigs.map((item: AlertConfigBean) => (
                <Option key={item.id} value={item.id as number}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="emails"
          label="E-mails"
          style={{ marginBottom: 16 }}
          rules={[
            {
              required: true,
              message: 'Please enter emails',
            },
          ]}
        >
          <Input placeholder="E-mails (comma-separated)" />
        </Form.Item>

        <Row align="stretch">
          <Col xs={12} sm={6} lg={4} xl={4} xxl={3} />
          <Col span={12} style={{ flexGrow: 1 }}>
            <Space>
              <CustomButton disabled={!fullAccess} type={fullAccess ? 'primary' : 'disabled'} icon={<CheckOutlined />}>
                {submitButtonName}
              </CustomButton>
              <CustomButton type="bordered" onClick={() => history.push(`${ALERT_PATH}?notification`)}>
                Cancel
              </CustomButton>
            </Space>
          </Col>
          <Col>
            {showArchiveButton && fullAccess && (
              <Space>
                <DeleteButtonPopConfirm
                  handleDelete={handleDelete as (e: any) => void}
                  id={alertNotificationBean?.id as number}
                  entityName="Alert Notification"
                />
              </Space>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}
