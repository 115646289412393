import { EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Input, Spin, Table, Tabs } from 'antd'
import Column from 'antd/lib/table/Column'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { alertApi } from 'api/alertApi'
import { alertConfigApi } from 'api/alertConfigApi'
import { alertNotificationApi } from 'api/alertNotificationApi'
import {
  AlertBean,
  AlertConfigBean,
  ListResponseAlertBean,
  ListResponseAlertConfigBean,
  ListResponseAlertNotificationBean,
} from 'api/base/api'

import { ALERT_CONFIG_PATH, ALERT_NOTIFICATION_PATH } from 'constants/routes'

import { usePickDates } from 'utils/usePickDates'
import { UserContext } from 'utils/userContext.tsx'

import { CustomButton } from 'components/customButton/CustomButton'
import { Error } from 'components/globalError'
import { StatHeader } from 'components/StatHeader'

import 'pages/alertConfig/alertSettings.scss'

const { TabPane } = Tabs

interface Props {
  updateNewAlertCounter: any
}

export const Alerts: React.FC<Props> = ({ updateNewAlertCounter }) => {
  const { fullAccess } = useContext(UserContext)
  const location = useLocation()

  const [inProgress, setInProgress] = useState(true)
  const [activeKey, setActiveKey] = useState('alerts')
  const [errorMessage, setErrorMessage] = useState<string>()

  const [listResponseOfAlertConfig, setListResponseOfAlertConfig] = useState<ListResponseAlertConfigBean | null>(null)

  const [listResponseOfAlertNotification, setListResponseOfAlertNotification] =
    useState<ListResponseAlertNotificationBean | null>(null)

  const [listResponseOfAlert, setListResponseOfAlert] = useState<ListResponseAlertBean | null>(null)

  const [showError, setShowError] = useState(false)

  const history = useHistory()

  const { startDate, endDate, setDates } = usePickDates()

  const loadAlertConfigData = () => {
    alertConfigApi.getAlertConfigList().then(
      (resp) => {
        if (resp) {
          setListResponseOfAlertConfig(resp)
        }
        setInProgress(false)
        setShowError(false)
      },
      () => {
        setErrorMessage(`Alert Config load error. 
                                    Please refresh the page or try again later. 
                                    If the problem persists, please contact Customer Support.`)
        setShowError(true)
      },
    )
  }

  const loadAlertNotificationData = () => {
    alertNotificationApi.getAlertNotificationList().then(
      (resp) => {
        if (resp) {
          setListResponseOfAlertNotification(resp)
        }
        setInProgress(false)
        setShowError(false)
      },
      () => {
        setErrorMessage(`Alert Notification load error. 
                                    Please refresh the page or try again later. 
                                    If the problem persists, please contact Customer Support.`)
        setShowError(true)
      },
    )
  }

  const loadAlertData = () => {
    alertApi.getPage(startDate, endDate).then(
      (resp) => {
        if (resp) {
          setListResponseOfAlert(resp)
        }
        setInProgress(false)
        setShowError(false)
      },
      () => {
        setShowError(true)
      },
    )
  }

  // const handleSearchConfigs = (selectedKeys: any, confirm: any, dataName: any) => {
  //   confirm()
  //   if (dataName === 'configName') {
  //     loadAlertConfigData(pageSize, 1, selectedKeys[0])
  //   }
  //   if (dataName === 'notificationName') {
  //     loadAlertNotificationData(pageSize, 1, selectedKeys[0])
  //   }
  // }
  //
  // const handleReset = (clearFilters: any) => {
  //   clearFilters()
  //   loadAlertConfigData(pageSize, 1)
  //   loadAlertNotificationData(pageSize, 1)
  // }

  const getSorter =
    (name: string) =>
    (
      a: {
        [key: string]: number | string
      },
      b: {
        [key: string]: number | string
      },
    ) => {
      if (typeof a[name] === 'number') {
        return (a[name] as number) - (b[name] as number)
      }
      return a[name] ? (a[name] as string).localeCompare(b[name] as string) : -1
    }

  // const getSearchDropdown =
  //   (dataName: string) =>
  //   ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         // ref={(node) => (this.searchInput = node)}
  //         placeholder={`Search ${dataName}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearchConfigs(selectedKeys, confirm, dataName)}
  //         style={{ width: 188, marginBottom: 8, display: 'block' }}
  //       />
  //       <Button
  //         type="primary"
  //         onClick={() => handleSearchConfigs(selectedKeys, confirm, dataName)}
  //         icon={<SearchOutlined />}
  //         size="small"
  //         style={{ width: 90, marginRight: 8 }}
  //       >
  //         Search
  //       </Button>
  //       <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
  //         Reset
  //       </Button>
  //     </div>
  //   )

  const getFilterDropdown =
    (dataName: string) =>
    ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={(node) => (this.searchInput = node)}
          placeholder={`Search ${dataName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <CustomButton
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </CustomButton>
        <CustomButton
          type="bordered"
          onClick={() => {
            clearFilters()
            confirm()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </CustomButton>
      </div>
    )

  // const getFilterIcon = () => (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />

  const alertDataSource = listResponseOfAlert?.data.map((item: any, index: number) => ({ key: index, ...item.data }))

  const alertConfigDataSource = listResponseOfAlertConfig?.data.map((item: any, index: number) => ({
    key: index,
    ...item.data,
  }))

  const alertNotificationDataSource = listResponseOfAlertNotification?.data.map((item: any, index: number) => ({
    key: index,
    ...item.data,
  }))

  useEffect(() => {
    if (location.search?.includes('alerts')) {
      setActiveKey('alerts')
    }
    if (location.search?.includes('setup')) {
      setActiveKey('setup')
    }
    if (location.search?.includes('notification')) {
      setActiveKey('notification')
    }
  }, [location.search])

  useEffect(() => {
    if (activeKey === 'alerts') {
      loadAlertData()
    }
    if (activeKey === 'setup') {
      loadAlertConfigData()
    }
    if (activeKey === 'notification') {
      loadAlertNotificationData()
    }
    // eslint-disable-next-line
  }, [startDate, endDate, activeKey])

  useEffect(() => {
    loadAlertConfigData()
    loadAlertNotificationData()
    updateNewAlertCounter()
    // eslint-disable-next-line
  }, [])

  function getFilterData(arr: any[] | undefined, getData: (bean: any) => any) {
    const filterData: {
      text: string
      value: string
    }[] = []
    arr?.forEach((value) =>
      filterData.push({
        text: getData(value),
        value: getData(value),
      }),
    )
    return filterData.filter((v, i, a) => a.findIndex((t) => v.text === t.text && v.value === t.value) === i)
  }

  function getStatusFilterData(arr: any[] | undefined, getData: (bean: any) => any) {
    const filterData: {
      text: string
      value: string
    }[] = []
    arr?.forEach((value) =>
      filterData.push({
        text: getData(value) === true ? 'Active' : getData(value) === false ? 'Hold' : getData(value),
        value: getData(value),
      }),
    )
    return filterData.filter((v, i, a) => a.findIndex((t) => v.text === t.text && v.value === t.value) === i)
  }

  function filterFunc(getData: (bean: any) => any) {
    return (value: any, record: any) =>
      getData(record as any)
        .toString()
        .localeCompare(value) === 0
  }

  function substringFilterFunc(getData: (bean: any) => any) {
    return (value: any, record: any) =>
      getData(record as any) != null &&
      getData(record as any)
        .toString()
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
  }

  function getColumnCollectionRender() {
    return (record: any) => {
      if (record?.length === 0) {
        return 'All'
      }
      let result = ''
      for (let i = 0; i < record.length; i += 1) {
        if (result.length + record[i].length < 28) {
          result += `, ${record[i]}`
        } else {
          result = `${result} ...`
          i = record.length
        }
      }
      return result.substring(2)
    }
  }

  return (
    <div className="alert-settings">
      <div className="alert-settings__page">
        <Tabs activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Alerts" key="alerts">
            <Error showError={showError} message={errorMessage} closable isAuthorised />
            <StatHeader
              startDate={startDate}
              endDate={endDate}
              setDates={setDates}
              doNotShowCurrencySwitch
              helpContent={null}
            />
            <Spin spinning={inProgress}>
              <Table
                dataSource={alertDataSource}
                className="config-list__page_table"
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                  position: ['bottomLeft'],
                  showSizeChanger: true,
                  pageSizeOptions: [15, 30, 50, 100],
                  defaultPageSize: 15,
                  // pageSize: listResponseOfAlert?.pagination.size,
                  // total: listResponseOfAlert?.pagination.total,
                  // current: listResponseOfAlert?.pagination.currentPage,
                  // onChange: (page: number, pageSize: number) => {
                  //     setPageSize(pageSize)
                  //     setCurrentPage(page)
                  // },
                }}
                size="small"
                scroll={{ x: 'max-content' }}
              >
                <Column
                  key="alertDate"
                  title="Date"
                  dataIndex="alertDate"
                  sorter={getSorter('alertDate')}
                  width="10%"
                />
                <Column
                  key="alertConfigName"
                  title="Alert Name"
                  dataIndex="alertConfigName"
                  render={(text, record) => (
                    <Link to={`${ALERT_CONFIG_PATH}/${record.alertConfigId}/edit/update`}>{text}</Link>
                  )}
                  filterDropdown={getFilterDropdown('alertConfigName')}
                  filters={getFilterData(alertDataSource, (bean: AlertBean) => bean.alertConfigName)}
                  onFilter={substringFilterFunc((bean: AlertBean) => bean.alertConfigName)}
                  width="10%"
                />
                <Column
                  key="granularity"
                  title="Granularity"
                  dataIndex="granularity"
                  filterDropdown={getFilterDropdown('granularity')}
                  filters={getFilterData(alertDataSource, (bean: AlertBean) => bean.granularity)}
                  onFilter={substringFilterFunc((bean: AlertBean) => bean.granularity)}
                  render={(_text) => _text.replace(/([a-z])([A-Z])/g, '$1 $2')}
                />
                <Column
                  key="siteName"
                  title="Site"
                  dataIndex="siteName"
                  filterDropdown={getFilterDropdown('siteName')}
                  filters={getFilterData(alertDataSource, (bean: AlertBean) => bean.siteName)}
                  onFilter={substringFilterFunc((bean: AlertBean) => bean.siteName)}
                  render={(record) => (record !== null ? record : '-')}
                />
                <Column
                  key="adUnitDictName"
                  title="Ad Unit"
                  dataIndex="adUnitDictName"
                  filterDropdown={getFilterDropdown('adUnitDictName')}
                  filters={getFilterData(alertDataSource, (bean: AlertBean) => bean.adUnitDictName)}
                  onFilter={substringFilterFunc((bean: AlertBean) => bean.adUnitDictName)}
                  render={(record) => (record !== null ? record : '-')}
                  width="10%"
                />
                <Column key="previousDate" title="Previous Date" dataIndex="previousDate" width="10%" />
                <Column key="currentDate" title="Current Date" dataIndex="currentDate" width="10%" />
                <Column key="previousValue" title="Previous Value" dataIndex="previousValue" width="10%" />
                <Column key="currentValue" title="Current Value" dataIndex="currentValue" width="10%" />
                <Column key="threshold" title="Threshold" dataIndex="threshold" render={(record) => `${record} %`} />
                <Column key="deviation" title="Deviation" dataIndex="deviation" render={(record) => `${record} %`} />
              </Table>
            </Spin>
          </TabPane>

          <TabPane tab="Setup" key="setup">
            <Error showError={showError} message={errorMessage} closable isAuthorised />
            <Spin spinning={inProgress}>
              <Table
                dataSource={alertConfigDataSource}
                className="alert-settings__page_table"
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                  position: ['bottomLeft'],
                  showSizeChanger: true,
                  pageSizeOptions: [15, 30, 50, 100],
                  defaultPageSize: 15,
                }}
                size="small"
                scroll={{ x: 'max-content' }}
              >
                <Column
                  key="name"
                  title="Alert Name"
                  dataIndex="name"
                  sorter={getSorter('name')}
                  filterDropdown={getFilterDropdown('name')}
                  filters={getFilterData(alertConfigDataSource, (bean: AlertConfigBean) => bean.name)}
                  onFilter={substringFilterFunc((bean: any) => bean.name)}
                />
                <Column
                  key="active"
                  title="Status"
                  dataIndex="active"
                  render={(_text, record) => (record.active ? 'Active' : 'Hold')}
                  sorter={getSorter('configActive')}
                  filters={getStatusFilterData(alertConfigDataSource, (bean: any) => bean.active)}
                  onFilter={filterFunc((bean: any) => bean.active)}
                />
                <Column
                  key="alertType"
                  title="Alert Type"
                  dataIndex="alertType"
                  sorter={getSorter('alertType')}
                  render={(_text) => _text.replace(/([a-z])([A-Z])/g, '$1 $2')}
                />
                <Column
                  key="granularity"
                  title="Granularity"
                  dataIndex="granularity"
                  sorter={getSorter('granularity')}
                  filterDropdown={getFilterDropdown('granularity')}
                  filters={getFilterData(alertConfigDataSource, (bean: AlertConfigBean) => bean.granularity)}
                  onFilter={substringFilterFunc((bean: any) => bean.granularity)}
                  render={(_text) => _text.replace(/([a-z])([A-Z])/g, '$1 $2')}
                />
                <Column
                  key="siteNames"
                  title="Sites"
                  dataIndex="siteNames"
                  sorter={getSorter('siteNames')}
                  filterDropdown={getFilterDropdown('siteNames')}
                  filters={getFilterData(alertConfigDataSource, (bean: AlertConfigBean) => bean.siteNames)}
                  onFilter={substringFilterFunc((bean: any) => bean.siteNames)}
                  render={getColumnCollectionRender()}
                />
                <Column
                  key="adUnitNames"
                  title="Ad Units"
                  dataIndex="adUnitNames"
                  sorter={getSorter('adUnitNames')}
                  filterDropdown={getFilterDropdown('adUnitNames')}
                  filters={getFilterData(alertConfigDataSource, (bean: AlertConfigBean) => bean.adUnitNames)}
                  onFilter={substringFilterFunc((bean: any) => bean.adUnitNames)}
                  render={getColumnCollectionRender()}
                />
                <Column
                  key="thresholdPercent"
                  title="Threshold %"
                  dataIndex="thresholdPercent"
                  sorter={getSorter('thresholdPercent')}
                />
                <Column
                  key="action"
                  title="Actions"
                  render={(record: AlertConfigBean) => (
                    <Link to={`${ALERT_CONFIG_PATH}/${record.id}/edit/update`}>
                      <CustomButton type="shadow" icon={<EditOutlined />}>
                        Edit
                      </CustomButton>
                    </Link>
                  )}
                />
              </Table>
              {fullAccess && (
                <CustomButton
                  type="primary"
                  onClick={() => history.push(`${ALERT_CONFIG_PATH}/create`)}
                  style={{ marginTop: 10, marginLeft: 10 }}
                >
                  Create new
                </CustomButton>
              )}
            </Spin>
          </TabPane>

          <TabPane tab="Notification" key="notification">
            <Error showError={showError} message={errorMessage} closable isAuthorised />
            <Spin spinning={inProgress}>
              <Table
                dataSource={alertNotificationDataSource}
                className="alert-settings__page_table"
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                  position: ['bottomLeft'],
                  showSizeChanger: true,
                  pageSizeOptions: [15, 30, 50, 100],
                  defaultPageSize: 15,
                }}
                size="small"
                scroll={{ x: 'max-content' }}
              >
                <Column
                  key="name"
                  title="Notification Name"
                  dataIndex="name"
                  sorter={getSorter('name')}
                  filterDropdown={getFilterDropdown('notificationName')}
                  filters={getFilterData(alertNotificationDataSource, (bean: any) => bean.name)}
                  onFilter={substringFilterFunc((bean: any) => bean.name)}
                />
                <Column
                  key="active"
                  title="Status"
                  dataIndex="active"
                  render={(_text, record) => (record.active ? 'Active' : 'Hold')}
                  sorter={getSorter('notificationActive')}
                  filters={getStatusFilterData(alertNotificationDataSource, (bean: any) => bean.active)}
                  onFilter={filterFunc((bean: any) => bean.active)}
                />
                <Column
                  key="alertConfigNames"
                  title="Alerts"
                  dataIndex="alertConfigNames"
                  sorter={getSorter('alertConfigNames')}
                  filterDropdown={getFilterDropdown('alertConfigNames')}
                  filters={getFilterData(alertNotificationDataSource, (bean: any) => bean.alertConfigNames)}
                  onFilter={substringFilterFunc((bean: any) => bean.alertConfigNames)}
                  render={getColumnCollectionRender()}
                />
                <Column
                  key="emails"
                  title="E-mails"
                  dataIndex="emails"
                  sorter={getSorter('emails')}
                  filterDropdown={getFilterDropdown('emails')}
                  filters={getFilterData(alertNotificationDataSource, (bean: any) => bean.emails)}
                  onFilter={substringFilterFunc((bean: any) => bean.emails)}
                />
                <Column
                  key="lastSentDate"
                  title="Last Sent Date"
                  dataIndex="lastSentDate"
                  sorter={getSorter('lastSentDate')}
                  render={(record) => (record !== null ? record : '-')}
                />

                <Column
                  key="action"
                  title="Actions"
                  render={(record: AlertConfigBean) => (
                    <Link to={`${ALERT_NOTIFICATION_PATH}/${record.id}/edit/update`}>
                      <CustomButton type="shadow" icon={<EditOutlined />}>
                        Edit
                      </CustomButton>
                    </Link>
                  )}
                />
              </Table>
              {fullAccess && (
                <CustomButton
                  type="primary"
                  onClick={() => history.push(`${ALERT_NOTIFICATION_PATH}/create`)}
                  style={{ marginTop: 10, marginLeft: 10 }}
                >
                  Create new
                </CustomButton>
              )}
            </Spin>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
