import { ExportOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import SubMenu from 'antd/es/menu/SubMenu'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { accountApi } from 'api/accountApi'

import { LOGOUT_PATH } from 'constants/routes'
import * as routes from 'constants/routes.ts'
import { dashboardTab, detailedStatsTabs, profileTabs, reportTabs, setupTabs } from 'constants/sidebarTabs'

import { UserContext } from 'utils/userContext'

import { ContactSupportModal } from 'components/ContactSupportModal'

import 'layout/sidebar.scss'

const { Sider } = Layout
// const HealthStateEnum = SiteHealth.HealthStateEnum

interface Props {
  selectedKeys: string[]
  collapsed: boolean
  email?: string
  alertCount: number
}

export const Sidebar: React.FC<Props> = ({ selectedKeys, collapsed, email, alertCount }) => {
  const { logoDataURI, fullAccess } = useContext(UserContext)

  const [modalIsVisible, setModalIsVisible] = useState(false)

  const doLogout = () => {
    accountApi.logout()
  }

  // const openModal = () => {
  //     setModalIsVisible(true)
  // }

  // const formatSiteName = (value: string) => {
  //     let len = value.length
  //     if (len > 12) {
  //         value = value.slice(0, 12) + '...'
  //     }
  //     return value
  // }

  useEffect(() => {}, [])

  return (
    <Sider className="sidebar" trigger={null} collapsible collapsed={collapsed} style={{ height: '100%' }}>
      <div className="sidebar__header">
        {collapsed ? (
          <div className="sidebar__logo_small">
            <img src={logoDataURI ?? '/img/logo_small.svg'} alt="AsterioBid" width={30} />
          </div>
        ) : (
          <div className="sidebar__logo">
            <img src={logoDataURI ?? '/img/logo.svg'} alt="AsterioBid" width={150} />
          </div>
        )}
      </div>

      <Menu theme="dark" mode="inline" className="sidebar__menu" selectedKeys={selectedKeys}>
        <Menu.ItemGroup className="sidebar__menu-main" key="dashboardTab">
          {dashboardTab.map((tab) => (
            <Menu.Item key={tab.key} icon={<tab.icon />}>
              {tab.url && (
                <NavLink to={tab.url}>
                  {tab.url === routes.ALERT_PATH && alertCount > 0 ? `${tab.name} (${alertCount})` : tab.name}
                </NavLink>
              )}
            </Menu.Item>
          ))}
        </Menu.ItemGroup>

        <Menu.ItemGroup className="sidebar__menu-main" key="detailedStatsTabs" title="Analysis">
          {detailedStatsTabs.map((tab) =>
            !tab.hasDropdown ? (
              <Menu.Item key={tab.key} icon={<tab.icon />}>
                {tab.url && <NavLink to={tab.url}>{tab.name}</NavLink>}
              </Menu.Item>
            ) : (
              <SubMenu key={tab.key} className="sidebar__tab" title={tab.name} icon={<tab.icon />}>
                {tab.content?.map((content) => (
                  <Menu.Item key={content.name}>
                    <NavLink to={content.url}>{content.name}</NavLink>
                  </Menu.Item>
                ))}
              </SubMenu>
            ),
          )}
        </Menu.ItemGroup>

        <Menu.ItemGroup className="sidebar__menu-main" key="reportTabs" title="Reports">
          {reportTabs.map((tab) =>
            !tab.hasDropdown ? (
              <Menu.Item key={tab.key} icon={<tab.icon />}>
                {tab.url && <NavLink to={tab.url}>{tab.name}</NavLink>}
              </Menu.Item>
            ) : (
              <SubMenu key={tab.key} className="sidebar__tab" title={tab.name} icon={<tab.icon />}>
                {tab.content?.map((content) => (
                  <Menu.Item key={content.name}>
                    <NavLink to={content.url}>{content.name}</NavLink>
                  </Menu.Item>
                ))}
              </SubMenu>
            ),
          )}
        </Menu.ItemGroup>

        <Menu.ItemGroup className="sidebar__menu-main" key="setupTabs" title="Setup">
          {setupTabs
            .filter((tab) => tab.fullAccess === undefined || tab.fullAccess === fullAccess)
            .map((tab) => (
              <Menu.Item key={tab.key} icon={<tab.icon />}>
                {tab.url && <NavLink to={tab.url}>{tab.name}</NavLink>}
              </Menu.Item>
            ))}
        </Menu.ItemGroup>

        <Menu.ItemGroup className="sidebar__menu-main" key="profileTabs" title="Profile">
          {profileTabs.map((tab) => (
            <Menu.Item key={tab.key} icon={<tab.icon />}>
              {tab.url && <NavLink to={tab.url}>{tab.name}</NavLink>}
            </Menu.Item>
          ))}
          {/* </Menu.ItemGroup> */}
          {/* <Menu.ItemGroup className="sidebar__menu-footer"> */}
          {/* <Divider className={"sidebar__divider"}/> */}
          <Menu.Item key="logout" icon={<ExportOutlined />} onClick={doLogout}>
            <NavLink to={LOGOUT_PATH}>Logout</NavLink>
          </Menu.Item>
          <ContactSupportModal email={email} modalIsVisible={modalIsVisible} setModalIsVisible={setModalIsVisible} />
        </Menu.ItemGroup>

        {/* <Menu.ItemGroup className="sidebar__menu-main" key={"setupTabs"} title="Config"> */}
        {/*    {setupTabs.map((tab) => */}
        {/*                       tab.hasDropdown ? ( */}
        {/*                           tab.isConfigTab ? ( */}
        {/*                               <SubMenu */}
        {/*                                   key={tab.key} */}
        {/*                                   title={tab.name} */}
        {/*                                   icon={<tab.icon/>} */}
        {/*                               > */}
        {/*                                   {tab.content?.map((content) => ( */}
        {/*                                       <Menu.Item */}
        {/*                                           key={content.name} */}
        {/*                                       ><NavLink to={content.url}> */}
        {/*                                           <Button */}
        {/*                                               type={"ghost"} */}
        {/*                                               className="sidebar__tab-add-new" */}
        {/*                                               icon={<PlusOutlined/>} */}
        {/*                                           > */}
        {/*                                               {content.name} */}
        {/*                                           </Button> */}
        {/*                                       </NavLink> */}

        {/*                                       </Menu.Item> */}
        {/*                                   ))} */}

        {/*                                   {sitesList */}
        {/*                                       .sort((a, b) => a.name.localeCompare(b.name)) */}
        {/*                                       .map((site) => ( */}
        {/*                                           <Menu.Item key={site.id}> */}
        {/*                                               <NavLink */}
        {/*                                                   to={ */}
        {/*                                                       routes.CONFIG_PATH + '/' +*/}
        {/*                                                       site.id +*/}
        {/*                                                       '/edit' +*/}
        {/*                                                       '/update' */}
        {/*                                                   } */}
        {/*                                               > */}
        {/*                                                   {formatSiteName(site.name)} */}
        {/*                                               </NavLink> */}
        {/*                                               {site.siteHealth.healthState !== */}
        {/*                                                HealthStateEnum.NotStarted ? ( */}
        {/*                                                    site.siteHealth */}
        {/*                                                        .trafficHoursAgo && */}
        {/*                                                    site.siteHealth */}
        {/*                                                        .trafficHoursAgo > 0 ? ( */}
        {/*                                                        <Tooltip */}
        {/*                                                            title={ */}
        {/*                                                                'We do not receive analytics events for ' +*/}
        {/*                                                                site.siteHealth */}
        {/*                                                                    .trafficHoursAgo +*/}
        {/*                                                                ' hours.' */}
        {/*                                                            } */}
        {/*                                                        > */}
        {/*                                                            <Badge */}
        {/*                                                                className="sidebar__tab-badge" */}
        {/*                                                                status="default" */}
        {/*                                                            /> */}
        {/*                                                        </Tooltip> */}
        {/*                                                    ) : ( */}
        {/*                                                        <Tooltip */}
        {/*                                                            title={ */}
        {/*                                                                'Site is working' */}
        {/*                                                            } */}
        {/*                                                        > */}
        {/*                                                            <Badge */}
        {/*                                                                className="sidebar__tab-badge" */}
        {/*                                                                status="success" */}
        {/*                                                            /> */}
        {/*                                                        </Tooltip> */}
        {/*                                                    ) */}
        {/*                                                ) : ( */}
        {/*                                                    <Tooltip */}
        {/*                                                        title={ */}
        {/*                                                            "We get no analytics from this site. Please check the site's setup" */}
        {/*                                                        } */}
        {/*                                                    > */}
        {/*                                                        <Badge */}
        {/*                                                            className="sidebar__tab-badge" */}
        {/*                                                            status="error" */}
        {/*                                                        /> */}
        {/*                                                    </Tooltip> */}
        {/*                                                )} */}
        {/*                                           </Menu.Item> */}
        {/*                                       ))} */}
        {/*                               </SubMenu> */}
        {/*                           ) : ( */}
        {/*                               <SubMenu */}
        {/*                                   key={tab.key} */}
        {/*                                   className="sidebar__tab" */}
        {/*                                   title={tab.name} */}
        {/*                                   icon={<tab.icon/>} */}
        {/*                               > */}
        {/*                                   {tab.content?.map((content) => ( */}
        {/*                                       <Menu.Item key={content.name}> */}
        {/*                                           <NavLink to={content.url}> */}
        {/*                                               {content.name} */}
        {/*                                           </NavLink> */}
        {/*                                       </Menu.Item> */}
        {/*                                   ))} */}
        {/*                               </SubMenu> */}
        {/*                           ) */}
        {/*                       ) : ( */}
        {/*                           <Menu.Item */}
        {/*                               key={tab.key} */}
        {/*                               icon={<tab.icon/>} */}
        {/*                           > */}
        {/*                               {tab.url && ( */}
        {/*                                   <NavLink to={tab.url}> */}
        {/*                                       {tab.name} */}
        {/*                                   </NavLink> */}
        {/*                               )} */}
        {/*                           </Menu.Item> */}
        {/*                       ) */}
        {/*    )} */}
        {/*    /!*</Menu.ItemGroup>*!/ */}
        {/*    /!*<Menu.ItemGroup className="sidebar__menu-footer">*!/ */}
        {/*    /!*<Divider className={"sidebar__divider"}/>*!/ */}
        {/*    <Menu.Item */}
        {/*        key="support" */}
        {/*        icon={<QuestionCircleOutlined/>} */}
        {/*        onClick={openModal} */}
        {/*    > */}
        {/*        Support */}
        {/*    </Menu.Item> */}
        {/*    <Menu.Item */}
        {/*        key="logout" */}
        {/*        icon={<ExportOutlined/>} */}
        {/*        onClick={doLogout} */}
        {/*    > */}
        {/*        <NavLink to={LOGOUT_PATH}>Logout</NavLink> */}
        {/*    </Menu.Item> */}
        {/*    <ContactSupportModal */}
        {/*        email={email} */}
        {/*        modalIsVisible={modalIsVisible} */}
        {/*        setModalIsVisible={setModalIsVisible} */}
        {/*    /> */}
        {/* </Menu.ItemGroup> */}
      </Menu>
    </Sider>
  )
}
