import { AlertControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class AlertApi {
  api = new AlertControllerApi(configuration, configuration.basePath, customFetch)

  // getOne(id: number) {
  //   return this.api.getAlertUsingGET(id)
  // }

  getPage(startDate: Date, endDate: Date) {
    return this.api.alertList({ startDate, endDate })
  }

  getTodayAlertCount() {
    return this.api.getNewAlertsCount()
  }
}

export const alertApi = new AlertApi()
