import Title from 'antd/es/typography/Title'

import 'pages/terms/terms.scss'

export const TermsPage = () => (
  <div className="terms">
    <div className="terms__page">
      <Title level={3} className="terms__page_title">
        Terms of Service
      </Title>
      <p dir="ltr">
        PLEASE READ THESE TERMS&amp;CONDITIONS OF SERVICE CAREFULLY BEFORE REGISTERING FOR ASTERIOBID PLATFORM. GETTING
        ASTERIOBID PLATFORM ACCOUNT AND PARTICIPATION IN ITS SERVICES INDICATES THAT YOU ACCEPT THESE TERMS AND
        CONDITIONS INCLUDING ANY CHANGES OR MODIFICATIONS WE MAY MAKE. IF YOU DO NOT ACCEPT THESE TERMS AND CONDITIONS,
        PLEASE DO NOT REGISTER OR CONTINUE TO PARTICIPATE.
      </p>
      <h1 dir="ltr">AsterioBid Platform Terms &amp; Conditions of Service</h1>
      <p dir="ltr">
        The company Asterio d.o.o., Dunajska cesta 106, Ljubljana, 1000, Slovenia proposes an analytic and reporting
        service of Header Bidding performance for websites and publisher, which it markets under the AsterioBid or
        AsterioBid Platform.
      </p>
      <p dir="ltr">
        This document is the Agreement that sets forth the terms between you (“Publisher”, “Customer” or “User”) and
        Asterio d.o.o. (“we”, “AsterioBid” or “AsterioBid Platform”). By accessing and using the AsterioBid, you agree
        to comply with and be bound by this Agreement unless otherwise specified in a separate document signed by both
        parties. Do not use the services if you disagree with any of the terms and conditions.
      </p>
      <h2 dir="ltr">1. Definitions.</h2>
      <br />
      <p dir="ltr">
        1.1 “Ad impression(s)” means an event in which an Ad is served to, and received by a unique visitor on a
        Publisher site.
      </p>
      <br />
      <p dir="ltr">
        1.2 “Ad request” Requests for Ads made by Publisher’s bidders for the Publisher’s inventory registered in
        AsterioBid Platform
      </p>
      <br />
      <p dir="ltr">
        1.3 “Registration Date” - the Date when User confirmed his/her Email used for Registration in AsterioBid
        Platform
      </p>
      <br />
      <p dir="ltr">
        1.4 “Trial Period” - the number of days after Registration Date when User can use any functionality of
        AsterioBid Platform without any charges
      </p>
      <br />
      <p dir="ltr">
        1.5 “Billing Plan” - specific rules of the subscription to the service(s) provided by AsterioBid Platform on
        which User payment calculations are made after the Trial Period is ended.
      </p>
      <br />
      <p dir="ltr">
        1.6 “User Account” - after Registration process user gets access to AsterioBid Platform via any internet browser
        with credentials that are defined by User in Registration process. User uses all AsterioBid Platform services
        and performs required payments only through this User Account.
      </p>
      <br />
      <p dir="ltr">1.7 “Service(s)” means any of the data, technology, and services provided by AsterioBid Platform.</p>
      <br />
      <h2 dir="ltr">2. Authorization.</h2>
      <p dir="ltr">
        2.1 Subject to these Agreement and the completion of Registration (see chapter 3) User may, during the Term,
        access and use the Service and the Service Output Data, strictly for User’s internal business purposes, and
        copy, install and use the AsterioBid Code on webpages, mobile applications, blogs and/or other properties that
        User lawfully own or control
      </p>
      <br />
      <p dir="ltr">
        2.2 We may, in our discretion, reject your request and/or remove any of your Properties from the Services at any
        time.
      </p>
      <br />
      <p dir="ltr">
        2.3 User authorizes us to collect, access, index and copy data related to your Properties. The Publisher retains
        ownership of the Publisher Data.
      </p>
      <br />
      <p dir="ltr">
        2.4 Any Publisher Data that we aggregate with other data (including our own data and third party data relating
        to your Properties) for any purpose shall be referred to herein as &quot;AsterioBid Data&quot;.
      </p>
      <br />
      <p dir="ltr">
        2.5 By entering this agreement, You represent that You are at least 18 years of age or reached the age of
        majority in the jurisdiction of your residence. You represent that you are a domain owner or you are authorized
        to enter this agreement with AsterioBid by domain owner.
      </p>
      <br />
      <p dir="ltr">
        2.6 You agree not to use Service in connection with (including but not limited to) any adware or spyware
        inventory, or any ad campaign on your site revealing precise geographic location, specific health / medical /
        sex-life information, credit card or financial data, or criminal convictions or charges, other sensitive
        personal data including racial or ethnic origin, political opinions, religious or philosophical beliefs
      </p>
      <br />
      <p dir="ltr">
        2.7 Use of the AsterioBid Public Site (asteriobid.com) or Services to violate the security of any computer
        network, crack passwords or security encryption codes, transfer or store illegal material including that which
        are deemed threatening or obscene, or engage in any kind of illegal activity is expressly prohibited. Any
        fraudulent, abusive, or otherwise illegal activity may be grounds for termination of access to Services.
      </p>
      <br />
      <h2 dir="ltr">3. Registration and User Account in AsterioBid Platform</h2>
      <p dir="ltr">
        3.1 User is required to register for and obtain an account for the Services of AsterioBid in order to access and
        use the Service.
      </p>
      <br />
      <p dir="ltr">
        3.2 In order to apply for registration, user must complete our online application form on public site{' '}
        <a href="http://www.asteriobid.com">www.asteriobid.com</a> or through a direct link to Login page.
      </p>
      <br />
      <p dir="ltr">
        3.3 To get an access to your account in AsterioBid Platform and complete the registration process you should
        confirm your email and complete Profile form in which we will indicate the mandatory fields for completion. You
        must provide true and complete personal information.
      </p>
      <br />
      <p dir="ltr">
        3.4 User adds Account credit card information during Registration. Card won&apos;t be charged if user blocks the
        account by clicking on the Block Account button at the bottom of the Profile page not later than the Trial
        Period is expired.
      </p>
      <br />
      <p dir="ltr">
        3.5 After email is confirmed and Profile form is submitted, User account is created automatically and access is
        provided.
      </p>
      <br />
      <p dir="ltr">3.6 User gets single AsterioBid user account for one confirmed email</p>
      <br />
      <p dir="ltr">
        3.7 Login to the AsterioBid Platform is authenticated with a password, which you should periodically change.
        User will ensure the security and confidentiality of all usernames and passwords associated with the Account
      </p>
      <br />
      <p dir="ltr">
        3.8 We may delete all User Data (including User Account and personal data) from the Service, immediately after
        the User request via email <a href="mailto:support@asteriobid.com">support@asteriobid.com</a>
      </p>
      <br />
      <p dir="ltr">3.9 AsterioBid Platform works as SaaS and no application downloads or installation required</p>
      <br />
      <p dir="ltr">
        3.10 If User decides to stop using the Service, User has to remove any AsterioBid analytics adapter code that
        has been added to his/her website(s).
      </p>
      <h2 dir="ltr">4. Payments Terms for AsterioBid services</h2>
      <h2 dir="ltr">4.1 General Payment Conditions</h2>
      <p dir="ltr">
        4.1.1 The User will pay AsterioBid on monthly basis, in accordance with this Agreement the applicable amounts as
        described below
      </p>
      <br />
      <p dir="ltr">4.1.2 All payments are to be calculated, invoiced and made in USD</p>
      <br />
      <p dir="ltr">
        4.1.3 All payments are to be made in the form of monthly automatic charge withdrawal from User’s credit card by
        AsterioBid Platform
      </p>
      <h2 dir="ltr">4.2 Payment terms</h2>
      <p dir="ltr">4.2.1 User Billing cycle is 1 month</p>
      <br />
      <p dir="ltr">
        4.2.2 Trial Period starts at the moment when user confirmed Account email: Trial Start Date is Email
        Confirmation Date
      </p>
      <br />
      <p dir="ltr">
        4.2.3 Trial Period is set equal to 7 days. AsterioBid reserves the right to change its Trial period conditions
        at any time for newly registered users. For the users already registered when the above Trial period rules are
        active, no changes for Trial period are applicable
      </p>
      <br />
      <p dir="ltr">4.2.4 Billing Start Date = next date after Trial End Date.</p>
      <br />
      <p dir="ltr">
        4.2.5 During Trial Period a User has the access to the full-scope current functionality of AsterioBid Platform
        with no limits for traffic, sites or bidders amount, etc and the usage is free of charge.
      </p>
      <br />
      <p dir="ltr">
        4.2.6 If User decides not to use the Service after the Trial Period User has to block the account by clicking on
        the Block Account button at the bottom of the Profile page before the Trial Period is expired and remove any
        AsterioBid analytics adapter code that has been added to his/her website(s).
      </p>
      <br />
      <p dir="ltr">
        4.2.7 When user blocks the account and/or removes adapter code the Service is stopped and no more User data is
        collecting by AsterioBid Platform.
      </p>
      <br />
      <p dir="ltr">
        4.2.8 If User’s traffic cost (see its calculation in 4.3) for the billing month is lower than $0.50, then this
        month is not billable, and the sum not to be transferred for future periods
      </p>
      <br />
      <p dir="ltr">
        4.2.9 AsterioBid Platform invoices User via email used for User Registration on 02:00 next day after User
        Billing cycle ends.
      </p>
      <br />
      <p dir="ltr">
        4.2.10 AsterioBid automatically withdraws the invoiced sum from Account credit card after 24hrs after monthly
        invoice was sent
      </p>
      <br />
      <p dir="ltr">
        4.2.11 If User wants to argue the sum in the invoice received (s)he should email to{' '}
        <a href="mailto:billing@asteriobid.com">billing@asteriobid.com</a>
        about this not later than withdrawal from Account’s credit card is made. The argues and adjustments to be
        treated individually with AsterioBid
      </p>
      <br />
      <p dir="ltr">
        4.2.12 If AsterioBid monthly withdrawal from Account’s credit card fails, AsterioBid will inform about the
        failure via email used for User Registration. The User has 48hrs to ensure the full payment from the Account
        Credit card.
      </p>
      <br />
      <p dir="ltr">
        4.2.13 If the withdrawal attempts still fail after 48hrs the Service is stopped and no more User data is
        collecting by AsterioBid Platform. The User account remains active and all old data available.
      </p>
      <br />
      <p dir="ltr">
        4.2.14 After being stopped User data collecting can be restarted in the same account after User email request to
        support@asteriobid.com and successful payment withdrawal for the last month served + 10% administrative fee.
      </p>
      <h2 dir="ltr">4.3 Payment Calculations.</h2>
      <br />
      <p dir="ltr">4.3.1 All payments under the Agreement will be based upon AsterioBid measurements</p>
      <br />
      <p dir="ltr">
        4.3.2 There are 3 Billing Plans in AsterioBid Platform. User chooses one at the registration step.
      </p>
      <br />
      <p dir="ltr">
        4.3.3 AsterioBid Monthly Billing Plan implies fixed price payments with no additional calculations.
      </p>
      <br />
      <p dir="ltr">4.3.4 Cost for 1 month of AsterioBid on Monthly Billing Plan is set to $20.</p>
      <br />
      <p>
        4.3.5 For customers who is already registered with AsterioBid Platform at $10/month Billing Plan the monthly
        payment amount remains the same.
      </p>
      <br />
      <p dir="ltr">
        4.3.6 Monthly Billing Plan sets a restriction on the traffic volume to be processed by AsterioBid Platform to
        200,000 Ad Impressions per day.
      </p>
      <br />
      <p dir="ltr">
        4.3.7 When 200,000 Ad Impressions are reached on Monthly Billing Plan, analytics stops renewing for the day and
        restarts again at 00:00.
      </p>
      <br />
      <p dir="ltr">4.3.8 AsterioBid CPM Billing Plan is based on Ad Unit Request events number.</p>
      <br />
      <p dir="ltr">4.3.9 Ad Unit Request monthly payment calculation is made based on formula:</p>
      <p dir="ltr">
        <i>Payment Amount = CPM*Number of Ad Unit Requests/1000</i>
      </p>
      <br />
      <p dir="ltr">4.3.10 Cost for 1.000 prebid.js Ad Unit Requests (CPM) is set to $0.004.</p>
      <br />
      <p dir="ltr">
        4.3.11 Custom Billing Plan is provided for the customers who have high traffic volumes. In this case the amount
        of payments is being discussed with each customer individually.
      </p>
      <br />
      <p dir="ltr">
        4.3.12 AsterioBid reserves the right to change its costs as well as Billing Plans scheme(s) and to institute new
        charges at any time, upon thirty (30) days prior notice to User, which may be sent by email or posted on the
        Site www.asteriobid.com
      </p>
      <br />
      <p dir="ltr">
        4.3.13 User has the right to argue and adjust any payments based on finalized results. Any disputes in relation
        to the amount withdrawn from Account’s Credit Card must be made by the User within 30 days of the date of the
        payments received or all such claims shall be waived.
      </p>
      <br />
      <h2 dir="ltr">5. Data.</h2>
      <p dir="ltr">
        5.1 Publisher and its third party ad serving providers have the sole and exclusive right to use all data derived
        from Publisher’s use of the Services, for any purpose related to Publisher’s business
      </p>
      <br />
      <p dir="ltr">
        5.2 AsterioBid may use and disclose the data derived from Publisher’s use of the Services (including data
        collected from visitors to the Website) solely
      </p>
      <p dir="ltr">
        (i) as aggregate Service statistics, which will not include personally identifiable information or information
        that identifies or would reasonably be expected to identify Publisher or its websites,
      </p>
      <p dir="ltr">(ii) to provide Services and enforce its rights under this Agreement, and</p>
      <p dir="ltr">
        (iii) if and as required by court order, law or governmental or regulatory agency (after, if permitted, giving
        reasonable notice to Publisher and using commercially reasonable efforts to provide Publisher with the
        opportunity to seek a protective order or the equivalent (at Publisher’s expense)).
      </p>
      <br />
      <p dir="ltr">
        5.3 AsterioBid agrees that Publisher’s non-aggregated data will not be used or disclosed to any third party by
        AsterioBid or its third party ad server (except as otherwise expressly permitted by this Agreement) without
        Publisher’s written consent. Publisher will not knowingly cause or permit any personally identifiable
        information to be provided to AsterioBid.
      </p>
      <h2 dir="ltr">6. Service Level Agreement (SLA)</h2>
      <p dir="ltr">
        6.1 This SLA applies separately to each of your Properties. AsterioBid will use commercially reasonable efforts
        to make the AsterioBid API and analytics collection available with a yearly uptime percentage of at least 99.9%.
        A yearly uptime percentage of 99.9% means we guarantee you will experience no more than 525.6 minutes per year
        of unavailability.
      </p>
      <br />
      <p dir="ltr">
        6.2 AsterioBid will use all commercially best efforts to make the display reporting available at all times.
      </p>
      <br />
      <h2 dir="ltr">7. Restrictions.</h2>
      <p dir="ltr">
        You will not (i) use the Services or the AsterioBid Data except as expressly authorized herein; (ii) use the
        AsterioBid Data for any purpose other than internal analytics purposes, (iii) copy, reproduce, modify,
        distribute, damage, interfere with, disassemble, decompile, reverse engineer or create derivative works of the
        Services or the AsterioBid Data; (iv) breach, disable, tamper with or interfere with the proper working of the
        Services or the AsterioBid Data or develop or use (or attempt) any workaround for any security measure related
        to the Services or the AsterioBid Data; or (v) place any Content on any of your Properties that (a) infringes or
        misappropriates a third party&apos;s intellectual property or other proprietary rights, (b)breaches a third
        party&apos;s rights or privacy or publicity or (c)contains or promotes Objectionable Activity (as defined
        below). &quot;Objectionable Activity&quot; means any content or activity that is (v) pornographic, illegal,
        fraudulent, false, deceptive, misleading, libelous, defamatory or threatening, (w) racist, hate speech or
        bullying, (x) adware, malware, spyware or any other malicious code or drive-by download applications.
        (z)&quot;spam,&quot; mail fraud, pyramid schemes, investment opportunities, or advice not permitted by law.
      </p>
      <br />
      <h2 dir="ltr">8. Privacy.</h2>
      <p dir="ltr">
        8. 1 Publisher and AsterioBid must maintain a privacy policy that provides notice of data collection practices
        related to the Services and usage of the Publisher Data and AsterioBid Data, including without limitation, use
        of a cookie, web beacon or other tracking mechanisms
      </p>
      <p dir="ltr">
        8. 2 Publisher and AsterioBid must operate in accordance with all Policies and all applicable privacy and data
        security laws and regulations.
      </p>
      <br />
      <h2 dir="ltr">9. Confidentiality.</h2>
      <p dir="ltr">9.1 Under this Agreement, “Confidential Information” means and includes:</p>
      <p dir="ltr">
        all nonpublic information disclosed by or for a party in relation to this Agreement, including any
        communications related to the AsterioBid Platform;
      </p>
      <p dir="ltr">
        any AsterioBid software, technology, programming, specifications, materials, guidelines and documentation
        relating to the AsterioBid Service;
      </p>
      <p dir="ltr">
        and any information that a reasonable person familiar with the Internet and online advertising would consider
        proprietary and confidential.
      </p>
      <p dir="ltr">
        Confidential Information does not include any information the party receiving the information (“Receiving
        Party”) can demonstrate (through legally sufficient evidence)
      </p>
      <p dir="ltr">
        - was rightfully within its possession without restriction on disclosure prior to it being furnished to it by
        the other party;
      </p>
      <p dir="ltr">
        - is now or hereafter comes into the public domain through no act or failure to act on the part of Receiving
        Party;
      </p>
      <p dir="ltr">
        - is rightfully disclosed or made available to Receiving Party on a non-confidential basis by a third party
        having the right to disclose it without an obligation of confidentiality;
      </p>
      <p dir="ltr">
        - is independently developed by Receiving Party without reference to any Confidential Information or the
        participation of any person who has had access to the Confidential Information.
      </p>
      <p dir="ltr">
        Any summaries, compilations or extracts of Confidential Information prepared by Receiving Party shall be deemed
        Confidential Information and shall be subject to the terms of this Agreement. All Confidential Information
        furnished under this Agreement shall remain the property of the disclosing party and shall be returned by that
        party by Receiving Party immediately upon request.
      </p>
      <br />
      <p dir="ltr">
        9.2 Confidentiality. The Receiving Party may only disclose Confidential Information to that party’s employees,
        attorneys, accountants and auditors only to the extent that such persons have a need to know such information
        and only insofar as such persons are bound by a nondisclosure agreement including the same conditions of
        confidence and non-use required by this Agreement. Receiving Party will not use any part of the Confidential
        Information for any purpose other than for the purposes established in this Agreement. As part of this
        obligation, Publisher agrees that Publisher shall not derive any commercial benefit, whether direct or indirect,
        from the Confidential Information, except to the extent established in this Agreement or if such benefit may be
        derived from a separate written agreement between AsterioBid and Publisher. Receiving Party shall promptly
        notify the other party of any unauthorized disclosure or use of Confidential Information, and shall provide all
        reasonable assistance to prevent or stop such disclosure or use, and/or to obtain compensation for such
        disclosure or use. Receiving Party shall not reverse engineer, decompile, copy or export any Confidential
        Information. Receiving Party shall take reasonable precautions to protect the Confidential Information
        including, without limitation, all precautions that the Receiving Party employs with respect to its own
        confidential information. Each party shall be responsible for any breach of confidentiality by its employees,
        agents and contractors. Neither party may disclose the terms of this Agreement without the prior consent of the
        other party.
      </p>
      <br />
      <p dir="ltr">
        9.3 Compelled Disclosure. Receiving Party may disclose Confidential Information to the extent necessary to
        comply with any applicable law, order, regulation, ruling, subpoena or order of a governmental authority or
        tribunal with competent jurisdiction; provided, however, that Receiving Party shall notify the other party of
        all such requests or requirements prior to disclosure so that party may seek appropriate protection or
        limitation of the disclosure, and if permissible by law, Receiving Party shall provide reasonable cooperation to
        AsterioBid and its legal counsel, in seeking such protection or limitation of the disclosure.
      </p>
      <br />
      <h2 dir="ltr">10. Intellectual Property Rights</h2>
      <p dir="ltr">
        Each Party agrees that it will not acquire right, title, or interest in or to the other Party’s intellectual
        property rights except as set forth in this agreement. The AsterioBid name and logo, and all related product and
        service names, design marks and slogans are the trademarks or registered trademarks of AsterioBid. All other
        product and service marks contained herein are the trademarks of their respective owners. Publisher may not use
        any AsterioBid trademarks or logos without the prior written consent of AsterioBid.
      </p>
      <h2 dir="ltr">11. Indemnification.</h2>
      <p dir="ltr">
        Each party will indemnify, defend and hold harmless the other party and its agents, affiliates and licensors
        from any and all losses, claims, damages, liabilities and expenses arising from third-party claims or any
        government or industry investigation, including reasonable costs of investigation and reasonable attorneys&apos;
        fees (&quot;Claims&quot;) arising out of or related to breaches of this Agreement or allegations thereof.
      </p>
      <br />
      <h2 dir="ltr">12. Changes to the Agreement and to the Services.</h2>
      <p dir="ltr">
        We are constantly changing and improving the Services. We may add or remove functionalities or features of the
        Services at any time, and we may suspend or stop a Service altogether.
      </p>
      <p dir="ltr">
        We may modify this Agreement at any time, and will inform about any modifications to this Agreement via Account
        email. The changes will be effective immediately. If you don&apos;t agree to any modified terms in the
        Agreement, you must terminate the agreement and stop using the Services within 30 days.
      </p>
      <br />
      <h2 dir="ltr">13. Term and Termination.</h2>
      <p dir="ltr">
        13.1 Term. The term of this Agreement shall begin on the Registration Date and continue for a period of one year
        from the Registration Date, and shall automatically renew for successive one (1) year terms, unless and until
        terminated in accordance with this Agreement.
      </p>
      <br />
      <p dir="ltr">
        13.2 Termination for Convenience. Either party may terminate this Agreement for any reason by providing the
        other party with thirty (30) days prior written notice.
      </p>
      <br />
      <p dir="ltr">
        13.3 Termination Rights. In addition to any other rights of termination provided for herein, this Agreement may
        be immediately terminated by a party if:
      </p>
      <p dir="ltr">
        - the other party commits a material breach of its obligations hereunder that is not cured within ten (10) days
        after written notice thereof from the non-breaching party, or
      </p>
      <p dir="ltr">
        - a petition in bankruptcy or other insolvency proceeding is filed by or against the other party, or if an
        application is made for the appointment of a receiver for the other party of its property, or if the other party
        makes an assignment for the benefit of creditors, is unable to pay its debts regularly as they become due, or
        ceases carrying on business in the ordinary course.
      </p>
      <p dir="ltr">
        - Upon termination or expiration of this Agreement, all rights granted herein will revert to the granting party
        and all licenses will terminate, and Publisher will make no further use of AsterioBid, or the Services.
      </p>
      <h2 dir="ltr">14. Assignment.</h2>
      <p dir="ltr">
        Publisher may not transfer or assign this Agreement or its obligations under this Agreement, in whole or in
        part, without the prior written consent of AsterioBid (which consent will not be unreasonably delayed or
        withheld), except that a party may assign all of its rights and obligations under this Agreement to a successor
        (whether by sale, acquisition, merger, or operation of law). In addition, Publisher shall be permitted to assign
        this Agreement to an affiliate of Publisher as part of an internal reorganization. This Agreement will be
        binding upon and inure to the benefit of the parties and their respective permitted successors and permitted
        assigns. Any assignment in violation of this Section shall be void.
      </p>
      <h2 dir="ltr">15. DISCLAIMERS.</h2>
      <p dir="ltr">
        THE SERVICES AND THE ASTERIOBID DATA ARE PROVIDED &quot;AS-IS&quot; AND AS AVAILABLE. TO THE EXTENT PERMITTED BY
        APPLICABLE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, THE TERMS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.
      </p>
      <h2 dir="ltr">16. LIMITATION ON LIABILITY.</h2>
      <p dir="ltr">
        IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER OR ANY THIRD PARTY FOR (I) ANY LOST PROFIT OR ANY
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS
        AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), EVEN IF SUCH PARTY HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR (II) ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR
        ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), IN EXCESS OF THE FEES RECEIVED BY THE PUBLISHER
        WITHIN THE PREVIOUS 12 MONTHS.
      </p>
      <p dir="ltr">LIMITATION OF LIABILITY</p>
      <p dir="ltr">
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT PAND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS
        AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
        EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
        INTANGIBLE LOSSES (EVEN IF TABLEAU HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE
        USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING
        FROM ANY GOODS, DATA, INFORMATION OR SERVICES OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO
        THROUGH, FROM OR AS A RESULT OF THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
        DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE
        SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
        LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH
        MAY NOT APPLY TO YOU.
      </p>
      <h2 dir="ltr">17. Miscellaneous.</h2>
      <p dir="ltr">
        Each party will, in all matters relating to this Agreement, act as an independent contractor. Except as
        expressly contemplated by this Agreement, neither party has, nor will represent that it has, any authority to
        assume or create any obligation, express or implied, on behalf of the other party, or to represent the other
        party in any capacity. If any provision of this Agreement is held to be unenforceable, the parties shall
        substitute for the affected provision an enforceable provision that best approximates the intent and economic
        effect of the affected provision. We will not be liable for any delay or failure to perform any of our
        obligations set forth in this Agreement due to causes beyond our reasonable control. You will not assign or
        transfer any rights or obligations under this Agreement without our prior written consent. Any attempted
        assignment or delegation in violation of this Agreement will be null, void and of no effect. This Agreement is
        accepted upon your use of the Services or by registering to have an Account. This Agreement (which includes our
        privacy policy and any other rules posted on our website) constitutes the entire agreement between you and us
        regarding the use of the Services and/or the AsterioBid Data. Our failure to exercise or enforce any right or
        provision of this Agreement shall not operate as a waiver of such right or provision. Except where otherwise
        indicated, any notice or communication under this Agreement shall be sent via email (i) if to you, to the email
        address submitted by you with your Account, and (ii) if to us, to info@asteriobid.com.
      </p>
      <h2 dir="ltr">18. Governing.</h2>
      <p dir="ltr">
        This Agreement shall be governed by and construed in accordance with the laws of the State of Wyoming and the
        federal laws of the United States applicable therein. For the purpose of all legal proceedings, this Agreement
        will be deemed to have been performed in the state of Wyoming and the courts of the State of Wyoming will have
        jurisdiction to entertain any action arising under this Agreement. The Parties to this Agreement each hereby
        attorn to the jurisdiction of the courts of the State of Wyoming in accordance with the foregoing and waive any
        objection to venue or any claim of inconvenient forum.
      </p>
      <h2 dir="ltr">19. Force Majeure.</h2>
      <p dir="ltr">
        Neither party shall be liable to the other party for any delay or failure in performance under this Agreement
        arising out of a cause beyond its control and without its fault or negligence. Such causes may include, but are
        not limited to fires, floods, earthquakes, strikes, unavailability of necessary utilities, blackouts, acts of
        God, acts of regulatory agencies, or national disasters.
      </p>
      <br />
    </div>
  </div>
)
