import { FrownOutlined, SyncOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import * as React from 'react'

import { CustomButton } from 'components/customButton/CustomButton'

interface Props {
  tryAgain: () => void
}

export const ErrorPage: React.FC<Props> = ({ tryAgain }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Result
      icon={<FrownOutlined />}
      title="Oops! Sorry, something went wrong."
      subTitle="An unexpected error has occurred. Please refresh the page"
      extra={
        <CustomButton type="primary" icon={<SyncOutlined />} onClick={tryAgain}>
          Try again
        </CustomButton>
      }
    />
  </div>
)
