import { AreaChartOutlined, CreditCardOutlined, EditOutlined } from '@ant-design/icons'

import * as routes from 'constants/routes'

import { env } from 'env/index'

type TopBarTab = {
  name: string
  url: string
  icon: typeof AreaChartOutlined
}

export const topBarTabs: TopBarTab[] = env.partnerInstallation
  ? [
      {
        name: 'Edit Profile',
        url: routes.PROFILE_PATH,
        icon: EditOutlined,
      },
    ]
  : [
      {
        name: 'Edit Profile',
        url: routes.PROFILE_PATH,
        icon: EditOutlined,
      },
      {
        name: 'Payments',
        url: routes.PAYMENTS_PATH,
        icon: CreditCardOutlined,
      },
      // {
      //     name: 'Terms of Service',
      //     url: routes.TERMS_OF_SERVICE_PATH,
      //     icon: FileProtectOutlined,
      // },
    ]
