import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { accountApi } from 'api/accountApi'

import { REGISTRATION_PATH, RESTORE_PATH } from 'constants/routes'

import { UserContext } from 'utils/userContext'

import { CustomButton } from 'components/customButton/CustomButton'
import { Error } from 'components/globalError'

import 'pages/login/login.scss'

export const LoginScreen: React.FC = () => {
  const [form] = Form.useForm()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [spinning, setSpinning] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)

  const location = useLocation()
  const history = useHistory()

  const redirectToTargetPage = () => {
    const stateFromRedirect = location.state as { redirectedFrom?: string }
    const redirectedFrom = stateFromRedirect?.redirectedFrom
    if (redirectedFrom && redirectedFrom !== '/logout') {
      history.push(redirectedFrom)
    }
  }

  const doLogin = () => {
    setSpinning(true)
    accountApi.login(username, password).then(
      () => {
        setSpinning(false)
        setShowError(false)
        redirectToTargetPage()
      },
      (err) => {
        setSpinning(false)
        setShowError(true)
        setErrorMessage(err)
      },
    )
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      doLogin()
    }
  }

  const { logoDataURI } = React.useContext(UserContext)

  return (
    <Row align="middle" justify="center" className="login-wrapper">
      <Col xs={24} sm={20} md={10} lg={8} xl={6} xxl={4}>
        <Form form={form} name="login" initialValues={{ remember: true }} onFinish={doLogin}>
          <div className="login">
            <div className="login_header">
              <div className="login_header__logo">
                <a href="https://asteriobid.com">
                  <img src={logoDataURI ?? '/img/logo_bright.svg'} alt="AsterioBid" width={150} />
                </a>
              </div>
              <Title className="login_header__title" level={4}>
                Login
              </Title>
            </div>

            <div className="login__content">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <div className="login__content_input">
                  <Input
                    addonBefore={<UserOutlined />}
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <div className="login__content_input">
                  <Input.Password
                    addonBefore={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </Form.Item>

              <Form.Item className="login__content_fi">
                {/* <Form.Item */}
                {/*    name="remember" */}
                {/*    valuePropName="checked" */}
                {/*    noStyle */}
                {/* > */}
                {/*    <Checkbox>Remember me </Checkbox> */}
                {/* </Form.Item> */}
                <span className="login__content_fi_forgot">
                  <Link
                    to={RESTORE_PATH}
                    // onClick={routeChangeToForgotPassword}
                  >
                    Forgot password?
                  </Link>
                </span>
              </Form.Item>

              <Form.Item>
                <Spin spinning={spinning}>
                  <CustomButton className="login__content_button" type="primary">
                    Login
                  </CustomButton>
                </Spin>
              </Form.Item>

              <div className="login_header__subtitle">
                <span className="login_header__subtitle_left">Don&apos;t have an account? </span>
                <span className="login_header__subtitle_right">
                  <Link to={REGISTRATION_PATH}>Sign up</Link>
                </span>
              </div>
            </div>

            <div className="login__error">
              <Error showError={showError} message={errorMessage} closable={false} />
            </div>
          </div>
        </Form>
      </Col>
    </Row>
  )
}
