import { BundleControllerApi, BundleCreateForm, SiteBean, SitePublicControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class SiteApi {
  api = new BundleControllerApi(configuration, configuration.basePath, customFetch)

  publicApi = new SitePublicControllerApi(configuration, configuration.basePath, customFetch)

  createSite(form: BundleCreateForm) {
    return this.api.create1(form)
  }

  updateSite(id: number, bean: SiteBean) {
    return this.api.updateSite(bean, id)
  }

  archive(id: number) {
    return this.api.archive1(id)
  }

  getSite(id: number) {
    return this.api.getSite1(id)
  }

  getSiteList({ name, domain, status, orderBy, order }: ListFilters, page: number, pageSize: number) {
    return this.api.bundleListFiltering(name, domain, status, orderBy, order, {
      query: { size: pageSize, page },
    })
  }

  getAllByAccount() {
    return this.api.allBundleByAccount()
  }
}

export interface ListFilters {
  name?: string
  domain?: string
  status: string[]
  orderBy?: 'name' | 'domain' | 'status' | 'lastStatsTime'
  order: 'ascend' | 'descend'
}

export const siteApi = new SiteApi()
