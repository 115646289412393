import { RegistrationControllerApi, RegistrationForm } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class RegistrationApi {
  api = new RegistrationControllerApi(configuration, configuration.basePath, customFetch)

  create(form: RegistrationForm) {
    return this.api.register(form)
  }
}

export const registrationApi = new RegistrationApi()
