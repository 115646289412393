import React, { createContext, useEffect, useMemo, useState } from 'react'

import { accountApi } from 'api/accountApi'
import { PublisherInfoBean } from 'api/base/api'

import { ColorPalette, colorPaletteManager } from 'utils/colorPalette'
import { userUpdateStore } from 'utils/userUpdateStore'

interface Values {
  isAuthorized: boolean
  isColorPaletteLoading: boolean
  logoDataURI: string | undefined
  fullAccess: boolean | undefined
}

const defaultValues: Values = {
  isAuthorized: false,
  isColorPaletteLoading: false,
  logoDataURI: undefined,
  fullAccess: false,
}

export const UserContext = createContext(defaultValues)

interface Props {
  children: React.ReactNode
}

// currently isAuthorized from this component is not used in system
// will be used in the future when we refactor authorization system

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [bean, setBean] = useState<PublisherInfoBean>()

  const refreshUserInfo = () =>
    accountApi
      .checkUserInfo()
      .then((bean) => {
        if (bean.settings) {
          colorPaletteManager.update(bean.settings as ColorPalette)
        } else {
          colorPaletteManager.reset()
        }

        setBean(bean)
      })
      .catch(() => {
        colorPaletteManager.reset()
        setBean(undefined)
      })

  useEffect(() => {
    refreshUserInfo()

    return userUpdateStore.subscribe(refreshUserInfo)
  }, [])

  const value = useMemo<Values>(
    () => ({
      isAuthorized: !!bean?.isLoggedIn,
      isColorPaletteLoading: !bean?.isLoggedIn,
      logoDataURI: bean?.logo ? `data:image/png;base64,${bean.logo}` : undefined,
      fullAccess: bean?.accessRights === PublisherInfoBean.AccessRightsEnum.FULL,
    }),
    [bean],
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
