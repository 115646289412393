/* global RequestInfo, RequestInit */
import isofetch from 'isomorphic-fetch'

import { processGlobalError } from 'api/utils/processGlobalError'

import { loginStore } from 'store/LoginStore'

const apiLogin = '/api/login'
const apiRegister = '/api/register'

export function customFetch(input: RequestInfo, init: RequestInit = {}) {
  init = {
    ...init,
    headers: {
      ...init.headers,
    },
  }

  const url = (input as Request).url || (input as string)
  if (!url.startsWith(apiLogin) && !url.startsWith(apiRegister)) {
    // append Auth headers
    const { accessToken } = loginStore

    if (accessToken) {
      init.headers = {
        ...init.headers,
        'X-Access-Token': accessToken,
      }
    }
  }

  if (init.body && !(init.headers && (init.headers as any)['Content-Type'])) {
    init = {
      ...init,
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  }
  return isofetch(input, init)
    .then((resp: Response) => {
      if (resp.ok) {
        return resp
      }

      if (resp && resp.status === 401) {
        loginStore.logout()
        return Promise.reject(resp)
      }

      return Promise.reject(resp)
    })
    .catch((err) => processGlobalError(err))
}
