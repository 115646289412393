import { ReloadOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import Title from 'antd/es/typography/Title'
import Link from 'antd/lib/typography/Link'
import { useState } from 'react'
import * as React from 'react'

import { accountApi } from 'api/accountApi'

import { ContactSupportModal } from 'components/ContactSupportModal'

import 'pages/registration/email-confirmation.scss'

interface Props {
  modalVisible: boolean
  email?: string
  onCancel: () => void
}

export const EmailConfirmationModal: React.FC<Props> = ({ modalVisible, email, onCancel }) => {
  const [contactSupportModal, setContactSupportModal] = useState(false)

  const handleResendConfirmationEmail = () => {
    accountApi.resendConfirmationEmail().then(message.success(`Confirmation email is sent to ${email}`))
  }

  return (
    <>
      <Modal
        title="Thank you for your registration with AsterioBid!"
        centered
        visible={modalVisible}
        footer={null}
        closable
        onCancel={onCancel}
      >
        <div className="email-confirmation">
          <div className="email-confirmation__page">
            <div className="email-confirmation__page_content">
              <Title level={5}>Please confirm your email address</Title>
              <p style={{ padding: '.25em' }} />
              <p>
                We emailed a confirmation link to <Link style={{ cursor: 'text' }}>{email}</Link>
              </p>
              <ol>
                <li>
                  Check your inbox and open the confirmation email. Follow the steps in the email to confirm your email
                  address.
                </li>
                <li>Sign in and fill in your profile details so we could confirm you&apos;re a real person</li>
              </ol>
              <p>You&apos;re ready to use our service to the maximum.</p>
              <p style={{ padding: '1em' }} />
              <Title level={5}>Didn&apos;t get a confirmation email?</Title>
              <p style={{ padding: '.25em' }} />
              <p>
                {' '}
                Check your spam folder or{' '}
                <Link onClick={handleResendConfirmationEmail}>
                  <ReloadOutlined /> resend confirmation email
                </Link>
              </p>
              <p>
                Have questions? Contact <Link onClick={() => setContactSupportModal(true)}>support@asteriobid.com</Link>
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <ContactSupportModal
        email={email}
        modalIsVisible={contactSupportModal}
        setModalIsVisible={setContactSupportModal}
      />
    </>
  )
}
