import { RestorePasswordActionForm, RestorePasswordControllerApi, RestorePasswordRequestForm } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class RestorePasswordApi {
  api = new RestorePasswordControllerApi(configuration, configuration.basePath, customFetch)

  restorePasswordRequest(form: RestorePasswordRequestForm) {
    return this.api.restorePasswordRequest(form)
  }

  restorePasswordAction(form: RestorePasswordActionForm) {
    return this.api.restorePasswordAction(form)
  }
}

export const restorePasswordApi = new RestorePasswordApi()
