import { LockOutlined } from '@ant-design/icons'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement, Token } from '@stripe/stripe-js'
import { Col, Form, Input, message, Row, Select, Space } from 'antd'
import Link from 'antd/lib/typography/Link'
import { useContext, useState } from 'react'
import * as React from 'react'

import { accountApi } from 'api/accountApi'
import { ProfileForm } from 'api/base/api'

import { env } from 'env/index.ts'

import { UserContext } from 'utils/userContext.tsx'

import { CustomButton } from 'components/customButton/CustomButton'

import { BlockAccountModal, Values } from 'pages/profile/BlockAccountModal'

const { Option } = Select

interface Props {
  profileForm?: ProfileForm
  setShowError: any
  setErrorMessage: any
  reloadProfile: () => void
  setLoading: any
}

export const ProfileFormContainer: React.FC<Props> = ({
  profileForm,
  setShowError,
  setErrorMessage,
  reloadProfile,
  setLoading,
}) => {
  const { fullAccess } = useContext(UserContext)
  const [form] = Form.useForm()
  const stripe = useStripe()

  const elements = useElements()

  const [showChangeCardInput, setShowChangeCardInput] = useState(false)
  const [cardIsEmpty, setCardIsEmpty] = useState(true)
  const [stripeError, setStripeError] = useState('')
  const [passwordInputOpen, setPasswordInputOpen] = useState(false)

  const [showBlockAccountModal, setShowBlockAccountModal] = useState(false)

  const handleStripeChange = (e: any) => {
    if (e.error) {
      setStripeError(e.error.message)
    }
    setCardIsEmpty(e.empty)
  }

  const initClientBlock = (values: Values) => {
    accountApi.blockStatByClient(values).then(() => {
      message.success('Account is blocked')
      setShowBlockAccountModal(false)
    })
  }

  const cancelBlock = () => {
    setShowBlockAccountModal(false)
    message.error('Account block cancelled')
  }

  const openModal = () => {
    setShowBlockAccountModal(true)
  }

  const createToken = async () => {
    if (!stripe || !elements) {
      return new Promise<Token>((_, reject) => {
        reject('no stripe')
      })
    }

    const cardElement = elements.getElement(CardElement)
    return stripe.createToken(cardElement as StripeCardElement).then((result) => {
      if (result.error) {
        return Promise.reject(result.error.message)
      }
      return result.token
    })
  }

  const handleProfileUpdate = (form: ProfileForm) => {
    setLoading(true)
    const hadCard = profileForm?.cardEntered

    if (hadCard && !showChangeCardInput) {
      doUpdate(form)
    } else {
      createToken()
        .then((token) => {
          form.cardTokenId = token.id
          form.cardLast4Digits = token.card?.last4
          doUpdate(form)
        })
        .catch((error) => {
          setErrorMessage(error)
          setShowError(true)
          setLoading(false)
        })
    }
  }

  const doUpdate = (form: ProfileForm) => {
    if (!profileForm?.emailIsConfirmed) {
      setLoading(false)
      message.error('Please confirm your email first')
      return
    }

    accountApi.update(form).then(
      (response) => {
        setLoading(false)
        if (response.form) {
          message.success('Profile update saved')
          setShowError(false)
          reloadProfile()
        } else {
          setErrorMessage('Profile update failed')
          setShowError(true)
          message.error('Profile update failed')
        }
      },
      (err) => {
        setLoading(false)
        setErrorMessage('Profile update failed')
        setShowError(true)
        setErrorMessage(err)
      },
    )
  }

  return (
    <Form
      form={form}
      labelCol={{
        xs: { span: 12 },
        sm: { span: 7 },
        md: { span: 6 },
        lg: { span: 4 },
        xl: { span: 3 },
        xxl: { span: 2 },
      }}
      wrapperCol={{
        xs: { span: 12 },
        sm: { span: 15 },
        md: { span: 14 },
        lg: { span: 16 },
        xl: { span: 17 },
        xxl: { span: 18 },
      }}
      onFinish={handleProfileUpdate}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        initialValue={profileForm?.firstName.value}
        rules={[{ required: true, message: 'Please input your First Name' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastName"
        initialValue={profileForm?.lastName?.value}
        rules={[{ required: false, message: 'Please input your Last Name' }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <Form.Item label="Email" name="email" initialValue={profileForm?.email}>
        <Input disabled placeholder={profileForm?.email && profileForm?.email} />
      </Form.Item>

      <Form.Item name="emailIsConfirmed" noStyle hidden initialValue={profileForm?.emailIsConfirmed}>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="phone"
        initialValue={profileForm?.phone}
        rules={[{ required: false, message: 'Please input your phone' }]}
      >
        <Input placeholder="+0 000 000 00 00" />
      </Form.Item>

      <Form.Item
        label="Profile Currency"
        name="userCurrency"
        initialValue={profileForm?.userCurrency.value ? profileForm.userCurrency.value : 'USD'}
      >
        <Select>
          <Option value="USD">USD</Option>
          <Option value="EUR">EUR</Option>
        </Select>
      </Form.Item>

      {!env?.partnerInstallation && (
        <Form.Item
          label="Card Number"
          name="token"
          className="form__card"
          rules={[
            {
              required: !profileForm?.cardEntered && (cardIsEmpty || stripeError === undefined),
              message: 'Please input your Card Number',
            },
          ]}
        >
          {profileForm?.cardEntered && (
            <div>
              <div className="form__card_number">
                {profileForm?.cardBrand} **** **** **** {profileForm?.cardLast4Digits}
              </div>
              <div className="form__card_link">
                <CustomButton
                  type="link"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowChangeCardInput(!showChangeCardInput)
                  }}
                >
                  Change card number
                </CustomButton>
              </div>
            </div>
          )}
          <div className="form__card_input" hidden={profileForm?.cardEntered && !showChangeCardInput}>
            <CardElement options={{ hidePostalCode: true }} onChange={handleStripeChange} />
          </div>
        </Form.Item>
      )}

      <Form.Item name="cardEntered" noStyle hidden initialValue={profileForm?.cardEntered}>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item label="Password" name="password" className="form__password" initialValue="">
        {passwordInputOpen ? (
          <div>
            <Link onClick={() => setPasswordInputOpen(!passwordInputOpen)}>Change password</Link>
            <Input.Password placeholder="Password" style={{ marginTop: '5px' }} />
          </div>
        ) : (
          <Link onClick={() => setPasswordInputOpen(!passwordInputOpen)}>Change password</Link>
        )}
      </Form.Item>

      <Row>
        <Col xs={12} sm={7} md={6} lg={4} xl={3} xxl={2} />
        <Col>
          <div className="profile__page_button-wrapper">
            <Space>
              <CustomButton disabled={!fullAccess} type={fullAccess ? 'primary' : 'disabled'}>
                Update
              </CustomButton>

              {fullAccess && (
                <CustomButton
                  type="warning"
                  icon={<LockOutlined />}
                  onClick={openModal}
                  disabled={!!profileForm?.billingProblem || !profileForm?.cardEntered}
                >
                  Block Account
                </CustomButton>
              )}
            </Space>

            {fullAccess && (
              <BlockAccountModal visible={showBlockAccountModal} onSubmit={initClientBlock} onCancel={cancelBlock} />
            )}
          </div>
        </Col>
      </Row>
    </Form>
  )
}
