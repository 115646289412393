import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'

import { ALERT_CONFIG_PATH } from 'constants/routes'

import { AlertConfigPage } from 'pages/alertConfig/AlertConfigPage'

export const AlertConfigIndexComponent = () => (
  <Switch>
    <Route path={`${ALERT_CONFIG_PATH}/create`} render={() => <AlertConfigPage />} />
    <Route
      path={`${ALERT_CONFIG_PATH}/:id/edit/:key`}
      render={(rp) => <AlertConfigPage id={+(rp.match.params.id as string)} />}
    />
    <Redirect from="/" to={`${ALERT_CONFIG_PATH}/create`} />
  </Switch>
)
