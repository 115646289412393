export const DASHBOARD_PATH = '/dashboard'
export const BIDDERS_PATH = '/bidders/'
export const BIDDERS_STAT_PATH = '/bidders/stat'
export const REPORTS_PATH = '/reports'
export const SITES_PATH = '/sites/'
export const SITES_LIST_PATH = '/site-list'
export const ALERT_LIST_PATH = '/alert-list'
export const ADUNIT_LIST_PATH = '/adunit-list'
export const ALERT_CONFIG_PATH = '/alert-config'
export const ALERT_NOTIFICATION_PATH = '/alert-notification'
export const SITES_STAT_PATH = '/sites/stat'
export const UTM_STAT_PATH = '/utm/stat/utmList'
export const ADTYPE_STAT_PATH = '/adtype/stat'
export const VERSION_COMPARISON_STAT_PATH = '/versionComparison/stat'
export const CONFIG_PATH = '/config'
export const ADUNIT_PATH = '/adunit'
export const ALERT_PATH = '/alert'
export const PROFILE_PATH = '/profile'
export const PAYMENTS_PATH = '/payments'
export const TERMS_OF_SERVICE_PATH = '/terms'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const REGISTRATION_PATH = '/registration'
export const RESTORE_PATH = '/restorePassword'
export const RESTORE_PASSWORD_EMAIL_SENT_PATH = '/restorePasswordEmailSent'
export const RESET_PASSWORD_PATH = '/resetPassword'
export const RESET_PASSWORD_SUCCESS_PATH = '/resetPasswordSuccess'
export const RESET_PASSWORD_EXPIRED_PATH = '/resetPasswordExpired'
export const RESET_PASSWORD_UNKNOWN_TOKEN_PATH = '/resetPasswordUnknownToken'
export const EMAIL_CONFIRMATION_SUCCESS_PATH = '/emailConfirmationSuccess'
export const EMAIL_CONFIRMATION_UNKNOWN_CODE_PATH = '/emailConfirmationUnknownCode'
export const EMAIL_CONFIRMATION_EXPIRED_CODE_PATH = '/emailConfirmationExpiredCode'
export const ERROR_PATH = '/error'
