import {
  DownOutlined,
  ExportOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  UpOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Layout, Menu, PageHeader } from 'antd'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'

import { accountApi } from 'api/accountApi'
import { ProfileBean } from 'api/base/api'

import {
  ADTYPE_TITLE,
  ADUNIT_LIST_TITLE,
  ALERT_TITLE,
  BIDDERS_TITLE,
  DASHBOARD_TITLE,
  PAYMENTS_TITLE,
  PROFILE_TITLE,
  REPORTS_TITLE,
  SITES_LIST_TITLE,
  SITES_TITLE,
  TERMS_OF_SERVICE_TITLE,
  UTM_TITLE,
  VERSION_COMPARISON_TITLE,
} from 'constants/pageTitles'
import * as routes from 'constants/routes'
import {
  ADTYPE_STAT_PATH,
  ADUNIT_LIST_PATH,
  ALERT_PATH,
  BIDDERS_PATH,
  BIDDERS_STAT_PATH,
  DASHBOARD_PATH,
  PAYMENTS_PATH,
  PROFILE_PATH,
  REPORTS_PATH,
  SITES_LIST_PATH,
  SITES_PATH,
  TERMS_OF_SERVICE_PATH,
  VERSION_COMPARISON_STAT_PATH,
} from 'constants/routes'
import { topBarTabs } from 'constants/topBarTabs'

import { ContactSupportModal } from 'components/ContactSupportModal'

import 'layout/topNav.scss'

const { Header } = Layout

interface Props {
  collapsed: boolean
  onClick: () => void
  profile: ProfileBean | null
}

export const TopNav: React.FC<Props> = ({ collapsed, onClick, profile }) => {
  const [title, setTitle] = useState('')
  const [topMenuCollapsed, setTopMenuCollapsed] = useState(false)
  const [modalIsVisible, setModalIsVisible] = useState(false)

  const toggleTopMenu = () => {
    setTopMenuCollapsed(!topMenuCollapsed)
  }

  const location = useLocation()

  const handleSetTitle = () => {
    if (location.pathname.includes(BIDDERS_PATH)) {
      setTitle(BIDDERS_TITLE)
    }
    if (location.pathname.includes(SITES_PATH)) {
      setTitle(SITES_TITLE)
    }

    switch (location.pathname) {
      case DASHBOARD_PATH:
        setTitle(DASHBOARD_TITLE)
        break
      case BIDDERS_STAT_PATH:
        setTitle(BIDDERS_TITLE)
        break
      case BIDDERS_PATH:
        setTitle(BIDDERS_TITLE)
        break
      case SITES_PATH:
        setTitle(SITES_TITLE)
        break
      case routes.UTM_STAT_PATH:
        setTitle(UTM_TITLE)
        break
      case ADTYPE_STAT_PATH:
        setTitle(ADTYPE_TITLE)
        break
      case VERSION_COMPARISON_STAT_PATH:
        setTitle(VERSION_COMPARISON_TITLE)
        break
      case REPORTS_PATH:
        setTitle(REPORTS_TITLE)
        break
      case SITES_LIST_PATH:
        setTitle(SITES_LIST_TITLE)
        break
      case ADUNIT_LIST_PATH:
        setTitle(ADUNIT_LIST_TITLE)
        break
      case ALERT_PATH:
        setTitle(ALERT_TITLE)
        break
      case PROFILE_PATH:
        setTitle(PROFILE_TITLE)
        break
      case PAYMENTS_PATH:
        setTitle(PAYMENTS_TITLE)
        break
      case TERMS_OF_SERVICE_PATH:
        setTitle(TERMS_OF_SERVICE_TITLE)
        break
      default:
    }
  }

  useEffect(handleSetTitle, [location.pathname])

  return (
    <Header className="top-nav">
      <div className="top-nav__left">
        <div className="top-nav__trigger">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            onClick,
          })}
        </div>
        <div className="top-nav__title">
          <PageHeader className="site-page-header" title={title} />
        </div>
      </div>
      <div className="top-nav__right">
        <Button
          style={{ color: 'black' }}
          icon={<QuestionCircleOutlined />}
          type="link"
          onClick={() => setModalIsVisible(true)}
        >
          Support
        </Button>

        <div className="top-nav__dropdown">
          <Dropdown overlay={<TopMenu />} trigger={['click']}>
            <div>
              <UserOutlined /> {profile?.email}{' '}
              {React.createElement(topMenuCollapsed ? UpOutlined : DownOutlined, {
                onClick: toggleTopMenu,
              })}
            </div>
          </Dropdown>
        </div>
        <ContactSupportModal
          email={profile?.email}
          modalIsVisible={modalIsVisible}
          setModalIsVisible={setModalIsVisible}
        />
      </div>
    </Header>
  )
}

export const TopMenu: React.FC = () => {
  const doLogout = () => {
    accountApi.logout()
  }
  return (
    <Menu>
      {topBarTabs.map((tab) => (
        <Menu.Item key={tab.name} icon={<tab.icon />}>
          <NavLink to={tab.url}>{tab.name}</NavLink>
        </Menu.Item>
      ))}
      <Menu.Item key="logout" className="sidebar__tab" icon={<ExportOutlined />} onClick={doLogout}>
        Logout
      </Menu.Item>
    </Menu>
  )
}
