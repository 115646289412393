import { Divider, Form, Input, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { RestorePasswordRequestForm } from 'api/base/api'
import { restorePasswordApi } from 'api/restorePasswordApi'

import { CustomButton } from 'components/customButton/CustomButton'
import { Error } from 'components/globalError'

import 'pages/resetpassword/restore.scss'

export const RestorePassword: React.FC = () => {
  const [form] = Form.useForm()

  const [success, setSuccess] = useState(false)
  const [spinning, setSpinning] = useState(false)
  const [showError, setShowError] = useState(false)

  const [errorMessage, setErrorMessage] = useState(
    'Password restore error. Please try again or contact Customer Support',
  )

  const sendRestorePasswordRequest = (form: RestorePasswordRequestForm) => {
    setSpinning(true)
    restorePasswordApi.restorePasswordRequest(form).then(
      (response) => {
        setSpinning(false)
        if (response.success) {
          setSuccess(response.success)
        } else {
          setShowError(true)
          setErrorMessage(response.form?.email.errorMessage as string)
        }
      },
      () => setShowError(true),
    )
  }

  return success ? (
    <Redirect to="/restorePasswordEmailSent" />
  ) : (
    <div className="reset-password">
      <div className="reset-password__header_title">
        <Title level={4}>Restore Password</Title>
      </div>
      <Divider />
      <div>
        Please enter the email address you used to create your account so we could send you a link to reset your
        password.
      </div>
      <br />
      <Form form={form} onFinish={sendRestorePasswordRequest}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email',
            },
          ]}
          className="reset-password__content"
        >
          <Input />
        </Form.Item>
        <Form.Item className="reset-password__footer">
          <Spin spinning={spinning}>
            <CustomButton type="primary">Submit</CustomButton>
          </Spin>
        </Form.Item>
      </Form>
      <div className="restore__error">
        <Error message={errorMessage} showError={showError} closable={false} />
      </div>
    </div>
  )
}
