import { Col, Layout, Row, Space } from 'antd'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

import 'layout/bottomNav.scss'

const { Footer } = Layout

export const BottomNav: React.FC = () => (
  <Footer className="bottom-nav">
    <Row justify="center">
      <Space>
        <Col>
          <NavLink activeClassName="active" to="/terms">
            Terms of Service
          </NavLink>
        </Col>
        <Col>AsterioBid ©2023</Col>
      </Space>
    </Row>
  </Footer>
)
