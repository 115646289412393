import { Content } from 'antd/es/layout/layout'
import React, { ReactNode } from 'react'

import 'layout/pageContent.scss'

interface Props {
  children: ReactNode
}

export const PageContent: React.FC<Props> = ({ children }) => <Content className="page-content">{children}</Content>
