import { Alert } from 'antd'
import * as React from 'react'

import { PaymentsHistoryBean, ProfileBean } from 'api/base/api'

const { BillingProblemEnum } = PaymentsHistoryBean

interface Props {
  showError: boolean
  message: any
  closable: boolean
  isAuthorised?: boolean
}

export const Error: React.FC<Props> = ({ message, showError, closable, isAuthorised }) =>
  !showError ? null : (
    <Alert
      type="error"
      style={!isAuthorised ? { margin: '0 0 1em' } : { margin: '0 1em 1em' }}
      message={message}
      closable={closable}
    />
  )

interface ErrorsProps {
  profile: ProfileBean
}

export const Errors: React.FC<ErrorsProps> = ({ profile }) => {
  const clientBlockMessage = (
    <div>
      Account is blocked. Analytics is stopped. To unblock please contact{' '}
      <a href="support@asteriobid.com">support@asteriobid.com </a>
    </div>
  )
  const adminBlockMessage = (
    <div>
      Account is blocked by administrator. Analytics is stopped. Contact{' '}
      <a href="support@asteriobid.com">support@asteriobid.com </a>
    </div>
  )

  const dailyImprsLimitReachedMessage = (
    <div>Daily Impressions Limit is reached. Analytics is stopped and will restart tomorrow</div>
  )

  const paymentFailureMessage = (
    <div>
      Payment failure. Analytics is stopped. Contact <a href="support@asteriobid.com">support@asteriobid.com </a>
    </div>
  )

  return (
    <>
      {profile.billingProblem && profile.billingProblem === BillingProblemEnum.AdminBlock && (
        <Error showError closable={false} message={adminBlockMessage} />
      )}
      {profile.billingProblem && profile.billingProblem === BillingProblemEnum.ClientBlock && (
        <Error showError closable={false} message={clientBlockMessage} />
      )}
      {profile.billingActivated &&
        profile.billingProblem &&
        profile.billingProblem !== BillingProblemEnum.AdminBlock &&
        profile.billingProblem !== BillingProblemEnum.ClientBlock &&
        !profile.inTrial && <Error showError closable={false} message={paymentFailureMessage} />}
      {profile.billingActivated &&
        profile.billingProblem &&
        profile.billingProblem === BillingProblemEnum.DailyImprsExceeded &&
        !profile.inTrial && <Error showError closable={false} message={dailyImprsLimitReachedMessage} />}
    </>
  )
}
