import { Layout } from 'antd'
import React, { ReactNode } from 'react'

import 'layout/mainContainer.scss'

interface Props {
  children: ReactNode
}

export const MainContainer: React.FC<Props> = ({ children }) => <Layout className="main-container">{children}</Layout>
