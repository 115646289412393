import { message, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { alertConfigApi } from 'api/alertConfigApi'
import { AdUnitDictBean, AlertConfigBean, BundleBean } from 'api/base/api'

import { ALERT_CONFIG_PATH, ALERT_PATH } from 'constants/routes'

import { Error } from 'components/globalError'

import { AlertConfigForm } from 'pages/alertConfig/AlertConfigForm'

import 'pages/alertConfig/alertPage.scss'

interface Props {
  id?: number
}

export const AlertConfigPage: React.FC<Props> = ({ id }) => {
  const [sites, setSites] = useState<BundleBean[]>([])
  const [adUnitDict, setAdUnitDict] = useState<AdUnitDictBean[]>([])
  const [alertConfig, setAlertConfig] = useState<AlertConfigBean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const [errorMessage, setErrorMessage] = useState(
    `Alert Config Load Error. 
        Please refresh the page or try again later. 
        If the problem persists, please contact Customer Support.`,
  )

  const loadAdUnitBean = (id: number) => {
    setIsLoading(true)
    alertConfigApi.getOne(id).then(
      (response) => {
        setAlertConfig(response.data)
        setIsLoading(false)
        setShowError(false)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage('Alert Config load error')
      },
    )
  }

  const loadInfo = () => {
    setIsLoading(true)
    alertConfigApi.getInfo().then(
      (response) => {
        setSites(response.sites)
        setAdUnitDict(response.adUnitDict)
        setIsLoading(false)
        setShowError(false)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage('Info load error')
      },
    )
  }

  const history = useHistory()

  const createAlertConfig = (form: any) => {
    alertConfigApi
      .create(form)
      .then((resp) => {
        if (resp.data.id) {
          history.push(`${ALERT_CONFIG_PATH}/${resp.data.id}/edit/setup`)
        }
        message.success('Alert Config added')
        window.prebidmanagerUiLibFiltersData = null
        history.push(`${ALERT_PATH}?setup`)
      })
      .catch((err) => {
        setErrorMessage(err)
        setShowError(true)
      })
  }

  const updateAlertConfig = (form: AlertConfigBean) => {
    const updateForm: AlertConfigBean = {
      id: form.id as number,
      name: form.name as string,
      active: form.active as boolean,
      alertType: form.alertType as AlertConfigBean.AlertTypeEnum,
      granularity: form.granularity as AlertConfigBean.GranularityEnum,
      siteIds: form.siteIds as number[],
      adUnitIds: form.adUnitIds as number[],
      thresholdValue: form.thresholdValue as number,
      thresholdPercent: form.thresholdPercent as number,
    }

    alertConfigApi.update(id as number, updateForm).then(
      () => {
        setIsLoading(false)
        message.success('Alert Config changes saved')
        window.prebidmanagerUiLibFiltersData = null
        history.push(`${ALERT_PATH}?setup`)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage(
          `Alert Config update failed. 
                    Please try again. 
                    If the problem persists, please contact Customer Support.`,
        )
      },
    )
  }
  const handleDelete = (id: number) => {
    alertConfigApi
      .delete(id)
      .then(() => {
        message.warn('Alert Config is deleted')
        window.prebidmanagerUiLibFiltersData = null
        history.push(`${ALERT_PATH}?setup`)
      })
      .catch((er) => {
        setErrorMessage(er)
        setShowError(true)
      })
  }

  useEffect(() => {
    loadInfo()
    if (id) {
      loadAdUnitBean(id)
    }
  }, [id])

  return (
    <div className="alert-page">
      <Error showError={showError} message={errorMessage} closable isAuthorised />
      <div className="alert-page__main">
        <Spin spinning={isLoading}>
          <div className="config-alert-form__title">
            {id ? <Title level={4}>Edit Alert</Title> : <Title level={4}>Add New Alert</Title>}
          </div>
          {!id ? (
            <AlertConfigForm
              sites={sites}
              adUnitDict={adUnitDict}
              submitButtonName="Add Alert Config"
              handleSubmit={createAlertConfig}
            />
          ) : (
            <AlertConfigForm
              sites={sites}
              adUnitDict={adUnitDict}
              alertConfigBean={alertConfig as AlertConfigBean}
              submitButtonName="Save"
              showArchiveButton
              handleSubmit={updateAlertConfig}
              handleDelete={handleDelete}
            />
          )}
        </Spin>
      </div>
    </div>
  )
}
