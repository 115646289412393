import { Col, Form, Input, Row, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { LOGIN_PATH } from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'
import { Error } from 'components/globalError'

import 'pages/registration/registration.scss'

interface Props {
  registrationInProgress: boolean
  onFinish: any
  showError: any
  errorMessage: string
}

export const RegistrationFormContainer: React.FC<Props> = ({
  registrationInProgress,
  onFinish,
  showError,
  errorMessage,
}) => {
  const [form] = Form.useForm()

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const tailLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  }

  return (
    <Row align="middle" justify="center" className="login-wrapper">
      <Col xs={24} sm={20} md={11} lg={9} xl={7} xxl={5}>
        <Form form={form} {...formLayout} className="registration" name="registration" onFinish={onFinish}>
          <Spin spinning={registrationInProgress}>
            <div className="registration_header">
              <div className="registration_header__logo">
                <a href="https://asteriobid.com">
                  <img src="/img/logo_bright.svg" alt="AsterioBid" />
                </a>
              </div>
              <Title className="registration_header__title" level={4}>
                Registration
              </Title>
            </div>

            <div className="registration_content">
              <Form.Item
                label="Email"
                labelAlign="left"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <div className="registration_content__input">
                  <Input placeholder="Email" />
                </div>
              </Form.Item>

              <Form.Item
                label="Password"
                labelAlign="left"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <div className="registration_content__input">
                  <Input.Password placeholder="Password" />
                </div>
              </Form.Item>

              <Form.Item name="billingPlan" initialValue="cpm" hidden />

              <Form.Item name="heedHelp" initialValue={false} hidden />

              <Form.Item name="referralCode" initialValue="" hidden />
              {/* <Form.Item */}
              {/*    valuePropName="checked" */}
              {/*    name="Agreement" */}
              {/*    rules={[ */}
              {/*        { */}
              {/*            required: true, */}
              {/*            validator: (_, value) => */}
              {/*                value */}
              {/*                    ? Promise.resolve() */}
              {/*                    : Promise.reject( */}
              {/*                          new Error( */}
              {/*                              'You should accept the agreement' */}
              {/*                          ) */}
              {/*                      ), */}
              {/*        }, */}
              {/*    ]} */}
              {/*    {...tailLayout} */}
              {/* > */}
              {/*    <Checkbox> */}
              {/*        I have read and accept the{' '} */}
              {/*        <a href={TERMS_OF_SERVICE_PATH}>Terms of Service</a> */}
              {/*    </Checkbox> */}
              {/* </Form.Item> */}

              <Form.Item {...tailLayout}>
                <CustomButton className="registration_content__button" type="primary">
                  Register
                </CustomButton>
              </Form.Item>

              <div className="registration__header_subtitle">
                <span className="registration_header__subtitle_left"> Already have an account? </span>
                <Link className="registration_header__subtitle_right" to={LOGIN_PATH}>
                  Login
                </Link>
              </div>
            </div>

            <div className="registration__error">
              <Error showError={showError} message={errorMessage} closable={false} />
            </div>
          </Spin>
        </Form>
      </Col>
    </Row>
  )
}
