import { globalErrorsStore } from 'store/GlobalErrorsStore'

export function processGlobalError(err?: any) {
  const resp = err as Response
  let parsedError: string | undefined

  if (typeof resp.json === 'function') {
    return resp
      .json()
      .catch(() => {
        globalErrorsStore.addError("ERROR: can't connect to the server")
        return Promise.reject(undefined)
      })
      .then((json) => {
        parsedError = (json.error || json) as string
        globalErrorsStore.addError(parsedError)
        return Promise.reject(parsedError)
      })
  }
  if ((resp as any).response && (resp as any).response.data) {
    const { data } = (resp as any).response
    parsedError = (data.error || data) as string
    globalErrorsStore.addError(parsedError)
  } else {
    globalErrorsStore.addError("ERROR: can't connect to the server")
  }

  return Promise.reject(parsedError)
}
