import { AlertConfigBean, AlertConfigControllerApi } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class AlertConfigApi {
  api = new AlertConfigControllerApi(configuration, configuration.basePath, customFetch)

  create(form: AlertConfigBean) {
    return this.api.createAlertConfig(form)
  }

  update(id: number, bean: AlertConfigBean) {
    return this.api.updateAlertConfig(bean, id)
  }

  delete(id: number) {
    return this.api.deleteAlertConfig(id)
  }

  getOne(id: number) {
    return this.api.getAlertConfig(id)
  }

  getAllAlertConfig() {
    return this.api.getAllAlertConfig()
  }

  getInfo() {
    return this.api.getInfo()
  }

  getAlertConfigList() {
    return this.api.getAlertConfigList()
  }
}

export const alertConfigApi = new AlertConfigApi()
