import { AdUnitControllerApi, AdUnitDictBean } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'

class AdUnitApi {
  api = new AdUnitControllerApi(configuration, configuration.basePath, customFetch)

  createAdUnitDict(form: AdUnitDictBean) {
    return this.api.createAdUnit(form)
  }

  updateAdUnitDict(id: number, bean: AdUnitDictBean) {
    return this.api.updateAdUnit(bean, id)
  }

  delete(id: number) {
    return this.api.deleteAdUnit(id)
  }

  getAdUnitDict(id: number) {
    return this.api.getAdUnit(id)
  }

  getAdUnitDictList() {
    return this.api.adUnitList()
  }
}

export const adUnitApi = new AdUnitApi()
