import { Alert, Form, Input, Modal, Radio } from 'antd'
import * as React from 'react'
import { useState } from 'react'

import { ClientBlockBean } from 'api/base/api'

export interface Values {
  cancelReason: ClientBlockBean.CancelReasonEnum
  cancelComments: string
}

interface CollectionCreateFormProps {
  visible: boolean
  onSubmit: (values: Values) => void
  onCancel: () => void
}

export const BlockAccountModal: React.FC<CollectionCreateFormProps> = ({ visible, onSubmit, onCancel }) => {
  const [form] = Form.useForm()
  const [value, setValue] = useState()

  const confirmationMessage = `You are about to block your account. 
    After your account is blocked all the analytics will be stopped.`

  return (
    <Modal
      visible={visible}
      title="Block Account"
      okText="Submit and Block"
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      onOk={() => {
        form.validateFields().then((values: Values) => {
          form.resetFields()
          onSubmit(values)
        })
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Alert type="warning" message={confirmationMessage} />
        <br />

        <Form.Item
          name="cancelReason"
          className="collection-create-form_last-form-item"
          label="Tell us why you leave"
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
            <Radio value={ClientBlockBean.CancelReasonEnum.HIGHPRICES}>High prices</Radio>
            <br />
            <Radio value={ClientBlockBean.CancelReasonEnum.INSUFFICIENTFUNCTIONALITY}>
              Insufficient functionality
              {value === ClientBlockBean.CancelReasonEnum.INSUFFICIENTFUNCTIONALITY ? (
                <Form.Item
                  noStyle
                  name="cancelComments"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your answer',
                    },
                  ]}
                >
                  <Input placeholder="What features would you like to have?" style={{ width: 294, marginLeft: 10 }} />
                </Form.Item>
              ) : null}
            </Radio>
            <br />
            <Radio value={ClientBlockBean.CancelReasonEnum.BADUI}>Inconvenient user interface</Radio>
            <br />
            <Radio value={ClientBlockBean.CancelReasonEnum.LOWCALCULATIONACCURACY}>Low accuracy of calculation</Radio>
            <br />
            <Radio value={ClientBlockBean.CancelReasonEnum.LONGLOAD}>Statistics load time is too long</Radio>
            <br />
            <Radio value={ClientBlockBean.CancelReasonEnum.DIFFICULTSETUP}>Difficult to setup prebid.js</Radio>
            <br />
            <Radio value={ClientBlockBean.CancelReasonEnum.NOTWHATISNEEDED}>
              The service is not exactly what I need
            </Radio>
            <br />
            <Radio value={ClientBlockBean.CancelReasonEnum.OTHER}>
              Other
              {value === ClientBlockBean.CancelReasonEnum.OTHER ? (
                <Form.Item
                  noStyle
                  name="cancelComments"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your answer',
                    },
                  ]}
                >
                  <Input
                    placeholder="Please specify"
                    style={{
                      width: 300,
                      marginLeft: 10,
                      marginTop: 15,
                    }}
                  />
                </Form.Item>
              ) : null}
            </Radio>
            <br />
            <br />
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
