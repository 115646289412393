import { ClockCircleOutlined } from '@ant-design/icons'
import { Divider, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useState } from 'react'

import { RESTORE_PATH } from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'

import 'pages/registration/registration.scss'

export const EmailConfirmationExpiredCodeScreen: React.FC = () => {
  const [spinning, setSpinning] = useState(false)

  return (
    <div className="registration">
      <div className="registration_header__title">
        <Title level={4}>
          Registration <ClockCircleOutlined />
        </Title>
        <Divider />
      </div>

      <div className="registration_content">
        <div className="registration__content">
          <p>Your email confirmation code is expired. Please request a new password reset link.</p>
        </div>
      </div>

      <div className="registration_footer">
        <Spin spinning={spinning}>
          <CustomButton type="primary" linkTo={RESTORE_PATH} onClick={() => setSpinning(true)}>
            Restore password
          </CustomButton>
        </Spin>
      </div>
    </div>
  )
}
