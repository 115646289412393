import moment from 'moment'
import { useState } from 'react'

export const usePickDates = () => {
  const [startDate, setStartDate] = useState(moment().utc(false).startOf('day').toDate())

  const [endDate, setEndDate] = useState(moment().utc(false).endOf('day').toDate())

  const setToday = () => {
    setStartDate(moment().utc(false).startOf('day').toDate())
    setEndDate(moment().utc(false).endOf('day').toDate())
  }

  const setYesterday = () => {
    setStartDate(moment().utc(false).subtract(1, 'days').startOf('day').toDate())
    setEndDate(moment().utc(false).subtract(1, 'days').endOf('day').toDate())
  }

  const setLast7Days = () => {
    setStartDate(moment().utc(false).subtract(6, 'days').startOf('day').toDate())
    setEndDate(moment().utc(false).endOf('day').toDate())
  }

  const setLast30Days = () => {
    setStartDate(moment().utc(false).subtract(29, 'days').startOf('day').toDate())
    setEndDate(moment().utc(false).endOf('day').toDate())
  }

  const setThisMonth = () => {
    setStartDate(moment().utc(false).startOf('month').startOf('day').toDate())
    setEndDate(moment().utc(false).endOf('month').endOf('day').toDate())
  }

  const setLastMonth = () => {
    setStartDate(moment().utc(false).subtract(1, 'month').startOf('month').startOf('day').toDate())
    setEndDate(moment().utc(false).subtract(1, 'month').endOf('month').endOf('day').toDate())
  }

  const setDates = (period: string | Array<string>) => {
    switch (period) {
      case 'today':
        setToday()
        break
      case 'yesterday':
        setYesterday()
        break
      case 'last7days':
        setLast7Days()
        break
      case 'last30days':
        setLast30Days()
        break
      case 'thisMonth':
        setThisMonth()
        break
      case 'lastMonth':
        setLastMonth()
        break
      default:
        if (Array.isArray(period) && !!period.length) {
          setStartDate(moment(period[0]).add(1, 'days').utc(false).startOf('day').toDate())
          setEndDate(moment(period[1]).add(1, 'days').utc(false).endOf('day').toDate())
        }
    }
  }

  return { startDate, endDate, setDates }
}
