import { Badge, message, Spin, Tabs } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { SiteBean, SiteHealth, UIAdUnitBidList, UIXValueString } from 'api/base/api'
import { siteApi } from 'api/siteApi'

import { CONFIG_PATH, SITES_LIST_PATH } from 'constants/routes'

import { Error } from 'components/globalError'

import { AnalyticsSetup } from 'pages/config/AnalyticsSetup'
import { ConfigSiteForm } from 'pages/config/ConfigSiteForm'

import 'pages/config/configPage.scss'

const { ErrorLevelEnum } = UIAdUnitBidList
const { HealthStateEnum } = SiteHealth

const { TabPane } = Tabs

interface Props {
  id?: number
  activeKey?: string
}

export const ConfigPage: React.FC<Props> = ({ id, activeKey }) => {
  const [siteBean, setSiteBean] = useState<SiteBean | null>(null)
  const hasTraffic = siteBean?.siteHealth.healthState !== HealthStateEnum.NotStarted
  const trafficMoreThenOneHourAgo = (siteBean?.siteHealth.trafficHoursAgo &&
    siteBean?.siteHealth.trafficHoursAgo > 0) as boolean
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const [errorMessage, setErrorMessage] = useState(
    `Site Load Error. 
        Please refresh the page or try again later. 
        If the problem persists, please contact Customer Support.`,
  )

  const formTab = (id: number) => (id ? 'Edit' : 'Create')

  const badgeStatus = hasTraffic ? (trafficMoreThenOneHourAgo ? 'default' : 'success') : 'error'

  const analyticsSetupTab = (id: number) =>
    !id ? (
      'Analytics Setup'
    ) : (
      <>
        <span style={{ paddingRight: '.5em' }}>Analytics Setup</span>
        <Badge status={badgeStatus} />
      </>
    )

  const loadSiteBean = (id: number) => {
    setIsLoading(true)
    siteApi.getSite(id).then(
      (response) => {
        setSiteBean(response)
        setIsLoading(false)
        setShowError(false)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage('Site load error')
      },
    )
  }

  const history = useHistory()

  const handleTabClick = (key: string) => {
    // eslint-disable-next-line
    history.push(CONFIG_PATH + '/' + id + '/edit' + `/${key}`)
  }

  const createSite = (form: any, protocol: string) => {
    const createForm = {
      description: form.description ? form.description : '',
      domain: (protocol as any) + form.domain,
      hasApprovedBidders: false,
      name: form.name,
      pageLayoutId: {
        errorMessage: 'null',
        typeahead: ['null'],
        errorLevel: ErrorLevelEnum.Success,
        value: '0',
      },
      prebidVersion: '1.0.23',
      tags: {
        errorMessage: 'null',
        typeahead: ['null'],
        value: 'null',
        empty: true,
      },
    }

    siteApi.createSite(createForm).then((resp) => {
      if (resp.bundleId) {
        history.push(`${CONFIG_PATH}/${resp.bundleId}/edit/setup`)
      }
      message.success('Site added')
      history.push(SITES_LIST_PATH)
    })
  }

  const updateSite = (form: SiteBean, protocol: string) => {
    const updateForm: SiteBean = {
      analyticSetupCode: siteBean?.analyticSetupCode as string,
      description: form.description as UIXValueString,
      domain: { value: protocol + form.domain },
      id: siteBean?.id as number,
      name: form.name as UIXValueString,
      siteHealth: siteBean?.siteHealth as SiteHealth,
    }

    siteApi.updateSite(id as number, updateForm).then(
      () => {
        setIsLoading(false)
        message.success('Site changes saved')
        history.push(SITES_LIST_PATH)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage(
          `Site update failed. 
                    Please try again. 
                    If the problem persists, please contact Customer Support.`,
        )
      },
    )
  }
  const handleArchive = (id: number) => {
    siteApi
      .archive(id)
      .then(() => {
        message.warn('Site is archived')
      })
      .then(() => history.push(SITES_LIST_PATH))
  }

  useEffect(() => {
    if (id) {
      loadSiteBean(id)
    }
  }, [id, activeKey])

  return (
    <div className="config-page">
      <Error showError={showError} message={errorMessage} closable isAuthorised />
      <div className="config-page__main">
        <Tabs type="card" activeKey={activeKey ?? 'update'} onChange={handleTabClick}>
          <TabPane key="update" tab={formTab(id as number)}>
            <Spin spinning={isLoading}>
              <div className="config-site-form__title">
                {id ? <Title level={4}>Edit {siteBean?.name.value}</Title> : <Title level={4}>Add new site</Title>}
              </div>
              {!id ? (
                <ConfigSiteForm submitButtonName="Add Site" handleSubmit={createSite} />
              ) : (
                <ConfigSiteForm
                  siteBean={siteBean as SiteBean}
                  submitButtonName="Save"
                  showArchiveButton
                  handleSubmit={updateSite}
                  handleArchive={handleArchive}
                />
              )}
            </Spin>
          </TabPane>

          <TabPane key="setup" disabled={!id} tab={analyticsSetupTab(id as number)}>
            <AnalyticsSetup
              siteBean={siteBean as SiteBean}
              hasTraffic={hasTraffic}
              trafficMoreThenOneHourAgo={trafficMoreThenOneHourAgo}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
