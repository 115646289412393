import { CheckCircleOutlined } from '@ant-design/icons'
import { Result, Spin } from 'antd'
import * as React from 'react'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { LOGIN_PATH } from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'

import 'pages/resetpassword/restore.scss'

export const ResetPasswordSuccessScreen: React.FC = () => {
  const [spinning, setSpinning] = useState(false)
  const [goToLogin, setGoToLogin] = useState(false)

  const handleClick = async () => {
    await setSpinning(true)
    setGoToLogin(true)
  }

  return goToLogin ? (
    <Redirect to={LOGIN_PATH} />
  ) : (
    <div className="reset-password">
      <Result
        icon={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
        title="Password changed!"
        subTitle="Your password has been changed successfully"
        extra={
          <Spin spinning={spinning}>
            <CustomButton type="primary" onClick={handleClick}>
              Login
            </CustomButton>
          </Spin>
        }
      />
    </div>
  )
}
