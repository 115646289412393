export const DASHBOARD_TITLE = 'Total Dashboard'
export const BIDDERS_TITLE = 'Bidder Analysis'
export const BIDDER_ANALYSIS_TITLE = `${BIDDERS_TITLE}Analysis`
export const BIDDER_RESPONSE_TIME_TITLE = `${BIDDERS_TITLE}Response Time`
export const BIDDER_INCREMENTAL_REVENUE_TITLE = `${BIDDERS_TITLE}Incremental Revenue`
export const REPORTS_TITLE = 'Reports'
export const SITES_LIST_TITLE = 'Sites'
export const ADUNIT_LIST_TITLE = 'Ad Unit Dictionary'
export const ALERT_CONFIG_SETTINGS_TITLE = 'Alert Settings'
export const ALERT_TITLE = 'Alerts'
export const SITES_TITLE = 'Site Analysis'
export const UTM_TITLE = 'UTM Analysis'
export const ADTYPE_TITLE = 'Media Type Analysis'
export const VERSION_COMPARISON_TITLE = 'Version Сomparison Analysis'
export const CREATE_NEW_SITE_TITLE = 'Add New Site'
export const CREATE_NEW_AD_UNIT_DICT_TITLE = 'Add New Ad Unit Name'
export const EDIT_AD_UNIT_DICT_TITLE = 'Edit Ad Unit Name'
export const EDIT_SITE_TITLE = 'Edit Site'
export const CREATE_SITE_TITLE = 'Add New Site'
export const PROFILE_TITLE = 'Profile'
export const PAYMENTS_TITLE = 'Payments'
export const TERMS_OF_SERVICE_TITLE = 'Terms of Service'
