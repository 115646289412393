import { CopyOutlined } from '@ant-design/icons'
import { Alert, Card, message } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'

import { SiteBean } from 'api/base/api'

import 'pages/config/analyticsSetup.scss'

interface Props {
  siteBean: SiteBean
  hasTraffic: boolean
  trafficMoreThenOneHourAgo: boolean
}

export const AnalyticsSetup: React.FC<Props> = ({ siteBean, hasTraffic, trafficMoreThenOneHourAgo }) => {
  const expectedCodeExample =
    'pbjs.que.push(function() {\n' +
    '    pbjs.enableAnalytics({\n' +
    "        provider: 'asteriobid',\n" +
    '        options: {\n' +
    "            url: 'https://xxxxxxxx-xxxx-xxxx/endpoint',\n" +
    "            bundleId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'\n" +
    '        }\n' +
    '    });\n' +
    '    pbjs.addAdUnits(adUnits);\n' +
    '    pbjs.requestBids({\n' +
    '        bidsBackHandler: initAdserver,\n' +
    '        timeout: PREBID_TIMEOUT\n' +
    '    });\n' +
    '});'

  const warningMessage = `We do not receive analytics events for ${siteBean?.siteHealth.trafficHoursAgo} hours.`

  return (
    <div className="analytics-setup__page">
      <div className="analytics-setup__page_title">
        <Title level={4}>Analytics Setup</Title>
        {hasTraffic ? (
          trafficMoreThenOneHourAgo ? (
            <Alert type="warning" message={warningMessage} />
          ) : (
            <Alert type="success" message=" Site is working." />
          )
        ) : (
          <Alert
            type="error"
            message="We still get no analytics from your site. Please complete your setup by following the instructions below."
          />
        )}
      </div>
      <Card type="inner" title="Download Prebid plugin">
        <p>
          Download{' '}
          <a href="https://docs.prebid.org/download.html" target="_blank" rel="noreferrer">
            Prebid.js
          </a>{' '}
          with AsterioBid Analytics Adapter included. Tick the box &quot;AsterioBid&quot; at Analytics Adapters section.
        </p>
        <p>Put the downloaded prebid.js into your standard folder for prebid.js on your site server. </p>
        <p>If you need some help with setup please contact our Support Team.</p>
      </Card>
      <Card
        style={{ marginTop: 16, position: 'relative' }}
        type="inner"
        title="Add the following code to your Prebid.js script before bids are requested (inside a pbjs.que.push block)"
      >
        <div
          className="analytics-setup__page_copy-button"
          onClick={() => {
            navigator.clipboard
              .writeText(siteBean?.analyticSetupCode as string)
              .then(() => message.success('Copied successfully'))
          }}
        >
          <CopyOutlined />
        </div>
        <pre className="analytics-setup__page_code-sample">{siteBean?.analyticSetupCode}</pre>
      </Card>
      <Card style={{ marginTop: 16, position: 'relative' }} type="inner" title="The expected result will look like:">
        <pre className="analytics-setup__page_code-sample">{expectedCodeExample}</pre>
      </Card>
    </div>
  )
}
