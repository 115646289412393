import { Layout } from 'antd'
import React, { ReactNode } from 'react'

import 'layout/body.scss'

interface Props {
  children: ReactNode
}

export const Body: React.FC<Props> = ({ children }) => (
  <div className="body">
    <Layout style={{ minHeight: '100vh' }}>{children}</Layout>
  </div>
)
