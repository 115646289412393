import { Spin } from 'antd'
import * as React from 'react'

import 'layout/progress.scss'

export const ProgressScreen: React.FC = () => (
  <div className="progress">
    <Spin size="large" tip="Loading..." />
  </div>
)
