function* uniqueIdGenerator(): Generator<number, number, number> {
  let i = 0
  while (true) {
    i += 1
    yield i
  }
}

const idGenerator = uniqueIdGenerator()

export const generateUniqueId = () => idGenerator.next().value
