import { CheckOutlined } from '@ant-design/icons'
import { Col, Form, Input, InputNumber, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { AdUnitDictBean, AlertConfigBean, BundleBean } from 'api/base/api'

import { ALERT_PATH } from 'constants/routes'

import { UserContext } from 'utils/userContext.tsx'

import { CustomButton } from 'components/customButton/CustomButton'

import { DeleteButtonPopConfirm } from 'pages/alertConfig/DeleteButtonPopConfirm'

import 'pages/alertConfig/alertForm.scss'

const { Option } = Select

interface Props {
  sites: BundleBean[]
  adUnitDict: AdUnitDictBean[]
  alertConfigBean?: AlertConfigBean
  showArchiveButton?: boolean
  handleSubmit: (form: any) => void
  handleDelete?: (e: any) => void
  submitButtonName: string
}

export const AlertConfigForm: React.FC<Props> = ({
  sites,
  adUnitDict,
  alertConfigBean,
  showArchiveButton,
  handleSubmit,
  handleDelete,
  submitButtonName,
}) => {
  const { fullAccess } = useContext(UserContext)
  const history = useHistory()

  const [selectedSites, setSelectedSites] = useState<any>(undefined)
  const [filteredAdUnits, setFilteredAdUnits] = useState<AdUnitDictBean[]>([])

  const [form] = useForm()
  const formLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
      lg: { span: 4 },
      xl: { span: 4 },
      xxl: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 14 },
      lg: { span: 16 },
      xl: { span: 16 },
      xxl: { span: 17 },
    },
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [alertConfigBean])

  const handleSelectAllSites = (value: any) => {
    if (value && value.length && value.includes('__all')) {
      if (value.length === sites.length + 1) {
        return []
      }
      setSelectedSites(undefined)
      return [...sites.map((el: BundleBean) => el.id)]
    }
    return value
  }

  const handleSelectAllAdUnits = (value: any) => {
    if (value && value.length && value.includes('__all')) {
      if (value.length === filteredAdUnits.length + 1) {
        return []
      }
      return [...filteredAdUnits.map((el: BundleBean) => el.id)]
    }
    return value
  }

  const onSitesChange = (value: any) => {
    setSelectedSites(value)
    form.setFieldsValue({ adUnitIds: [] })
  }

  const filterAdUnit = () => {
    if (selectedSites && selectedSites.includes('__all')) {
      setFilteredAdUnits(adUnitDict)
      return
    }
    if (!selectedSites && alertConfigBean?.siteIds && alertConfigBean.siteIds.length > 0) {
      setFilteredAdUnits(adUnitDict.filter((e) => alertConfigBean.siteIds?.includes(e.siteId as any)))
      return
    }
    if (selectedSites && selectedSites.length > 0) {
      setFilteredAdUnits(adUnitDict.filter((e) => selectedSites.includes(e.siteId as any)))
      return
    }

    setFilteredAdUnits(adUnitDict)
  }

  // eslint-disable-next-line
  useEffect(() => filterAdUnit(), [adUnitDict, selectedSites])

  return (
    <div className="config-alert-form__form">
      <Form
        form={form}
        {...formLayout}
        initialValues={{
          name: alertConfigBean?.name,
          active: alertConfigBean?.active,
          alertType: alertConfigBean?.alertType,
          granularity: alertConfigBean?.granularity,
          siteIds: alertConfigBean?.siteIds,
          adUnitIds: alertConfigBean?.adUnitIds,
          thresholdValue: alertConfigBean?.thresholdValue,
          thresholdPercent: alertConfigBean?.thresholdPercent,
        }}
        onFinish={(form) => handleSubmit(form)}
      >
        <Form.Item
          name="name"
          label="Alert Name"
          rules={[
            {
              required: true,
              message: 'Please enter Alert Name',
            },
          ]}
        >
          <Input placeholder="Alert Name" />
        </Form.Item>

        <Form.Item name="active" label="Status" initialValue>
          <Select defaultValue>
            <Option key="true" value>
              Active
            </Option>
            <Option key="false" value={false}>
              Hold
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="alertType"
          label="Alert Type"
          rules={[{ required: true, message: 'Please select Alert Type' }]}
        >
          <Select placeholder="Select Alert Type">
            {Object.keys(AlertConfigBean.AlertTypeEnum).map((mode) => (
              <Option key={mode} value={mode}>
                {mode.replace(/([a-z])([A-Z])/g, '$1 $2')}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="granularity"
          label="Granularity"
          rules={[{ required: true, message: 'Please select Alert Granularity' }]}
        >
          <Select placeholder="Select Alert Granularity">
            {Object.keys(AlertConfigBean.GranularityEnum).map((mode) => (
              <Option key={mode} value={mode}>
                {mode.replace(/([a-z])([A-Z])/g, '$1 $2')}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="siteIds" label="Sites" getValueFromEvent={handleSelectAllSites} initialValue={[]}>
          <Select showSearch allowClear mode="multiple" placeholder="All Available" onChange={onSitesChange}>
            {sites.length > 0 && <Option value="__all">Select all</Option>}
            {sites.map((item: BundleBean) => (
              <Option key={item.id} value={item.id as number}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="adUnitIds" label="Ad Units" getValueFromEvent={handleSelectAllAdUnits} initialValue={[]}>
          <Select showSearch allowClear mode="multiple" placeholder="All Available">
            {filteredAdUnits.length > 0 && <Option value="__all">Select all</Option>}
            {filteredAdUnits.map((item: AdUnitDictBean) => (
              <Option key={item.id} value={item.id as number}>
                {item.adUnitName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item required label="Check when" style={{ marginBottom: 8 }}>
          <Form.Item
            name="thresholdValue"
            rules={[{ required: true, message: 'Required' }]}
            className="ant-item ant-item-first"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item className="ant-item ant-item-second">&nbsp;or more requests were registered</Form.Item>
        </Form.Item>

        <Form.Item required label="Threshold Percent" style={{ marginBottom: 8 }}>
          <Form.Item
            name="thresholdPercent"
            rules={[{ required: true, message: 'Required' }]}
            className="ant-item ant-item-first"
          >
            <InputNumber step={1} min={0} max={100} />
          </Form.Item>
          <Form.Item className="ant-item ant-item-second">&nbsp;%</Form.Item>
        </Form.Item>

        <Row align="stretch">
          <Col xs={12} sm={6} lg={4} xl={4} xxl={3} />
          <Col span={12}>
            <Space>
              <CustomButton disabled={!fullAccess} type={fullAccess ? 'primary' : 'disabled'} icon={<CheckOutlined />}>
                {submitButtonName}
              </CustomButton>
              <CustomButton type="bordered" onClick={() => history.push(`${ALERT_PATH}?setup`)}>
                Cancel
              </CustomButton>
            </Space>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {showArchiveButton && fullAccess && (
              <Space>
                <DeleteButtonPopConfirm
                  handleDelete={handleDelete as (e: any) => void}
                  id={alertConfigBean?.id as number}
                  entityName="Alert Config"
                />
              </Space>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}
