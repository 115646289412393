import { EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Input, Spin, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { adUnitApi } from 'api/adUnitApi'
import { AdUnitDictBean, ListResponseAdUnitDictBean } from 'api/base/api'

import { ADUNIT_PATH } from 'constants/routes'

import { UserContext } from 'utils/userContext.tsx'

import { CustomButton } from 'components/customButton/CustomButton'
import { Error } from 'components/globalError'

import 'pages/adunit/adUnitList.scss'

export const AdUnitList: React.FC = () => {
  const { fullAccess } = useContext(UserContext)
  const location = useLocation()

  const [inProgress, setInProgress] = useState(true)
  const [listResponseOfAdUnit, setListResponseOfAdUnit] = useState<ListResponseAdUnitDictBean | null>(null)
  const [showError, setShowError] = useState(false)
  const history = useHistory()

  const errorMessage = `Ad Unit Dictionary load error. 
                                                    Please refresh the page or try again later. 
                                                    If the problem persists, please contact Customer Support.`

  const loadData = () => {
    adUnitApi.getAdUnitDictList().then(
      (resp) => {
        if (resp) {
          setListResponseOfAdUnit(resp)
        }
        setInProgress(false)
        setShowError(false)
      },
      () => {
        setShowError(true)
      },
    )
  }

  const getSorter =
    (name: string) => (a: { [key: string]: number | string }, b: { [key: string]: number | string }) => {
      if (typeof a[name] === 'number') {
        return (a[name] as number) - (b[name] as number)
      }
      return a[name] ? (a[name] as string).localeCompare(b[name] as string) : -1
    }

  const getFilterDropdown =
    (dataName: string) =>
    ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={(node) => (this.searchInput = node)}
          placeholder={`Search ${dataName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <CustomButton
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </CustomButton>
        <CustomButton
          type="bordered"
          onClick={() => {
            clearFilters()
            confirm()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </CustomButton>
      </div>
    )

  function getFilterData(arr: any[] | undefined, getData: (bean: any) => any) {
    const filterData: {
      text: string
      value: string
    }[] = []
    arr?.forEach((value) =>
      filterData.push({
        text: getData(value),
        value: getData(value),
      }),
    )
    return filterData.filter((v, i, a) => a.findIndex((t) => v.text === t.text && v.value === t.value) === i)
  }

  function substringFilterFunc(getData: (bean: any) => any) {
    return (value: any, record: any) =>
      getData(record as any) != null &&
      getData(record as any)
        .toString()
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
  }

  const dataSource = listResponseOfAdUnit?.data.map((item: any, index: number) => ({ key: index, ...item.data }))

  useEffect(() => {
    loadData()
  }, [location])

  return (
    <>
      <Error showError={showError} message={errorMessage} closable isAuthorised />

      <div className="config-list">
        <div className="config-list__page">
          <Spin spinning={inProgress}>
            <Table
              dataSource={dataSource}
              className="config-list__page_table"
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                position: ['bottomLeft'],
                showSizeChanger: true,
                pageSizeOptions: [15, 30, 50, 100],
                defaultPageSize: 15,
              }}
              size="small"
              scroll={{ x: 'max-content' }}
            >
              <Column
                key="siteName"
                title="Site"
                dataIndex="siteName"
                sorter={getSorter('siteName')}
                filterDropdown={getFilterDropdown('siteName')}
                filters={getFilterData(dataSource, (bean: AdUnitDictBean) => bean.siteName)}
                onFilter={substringFilterFunc((bean: AdUnitDictBean) => bean.siteName)}
              />
              <Column
                key="adUnitCode"
                title="Ad Unit Code"
                dataIndex="adUnitCode"
                sorter={getSorter('adUnitCode')}
                filterDropdown={getFilterDropdown('adUnitCode')}
                filters={getFilterData(dataSource, (bean: AdUnitDictBean) => bean.adUnitCode)}
                onFilter={substringFilterFunc((bean: AdUnitDictBean) => bean.adUnitCode)}
              />
              <Column
                key="adUnitName"
                title="Ad Unit Name"
                dataIndex="adUnitName"
                sorter={getSorter('adUnitName')}
                filterDropdown={getFilterDropdown('adUnitName')}
                filters={getFilterData(dataSource, (bean: AdUnitDictBean) => bean.adUnitName)}
                onFilter={substringFilterFunc((bean: AdUnitDictBean) => bean.adUnitName)}
              />
              <Column
                key="action"
                title="Actions"
                render={(record: AdUnitDictBean) => (
                  <Link to={`${ADUNIT_PATH}/${record.id}/edit/update`}>
                    <CustomButton type="shadow" title="Edit">
                      <EditOutlined />
                      Edit
                    </CustomButton>
                  </Link>
                )}
              />
            </Table>
            {fullAccess && (
              <CustomButton
                type="primary"
                onClick={() => history.push('/adunit/create')}
                style={{ marginTop: 10, marginLeft: 10 }}
              >
                Create new
              </CustomButton>
            )}
          </Spin>
        </div>
      </div>
    </>
  )
}
