import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from 'src/App.tsx'

import { UserContextProvider } from 'utils/userContext'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </BrowserRouter>,
)
