import { CheckOutlined } from '@ant-design/icons'
import { Divider, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useState } from 'react'

import { LOGIN_PATH } from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'

import 'pages/registration/registration.scss'

export const EmailConfirmationSuccessScreen: React.FC = () => {
  const [spinning, setSpinning] = useState(false)

  return (
    <div className="registration">
      <div className="registration_header__title">
        <Title level={4}>
          Registration <CheckOutlined />
        </Title>
        <Divider />
      </div>

      <div className="registration_content">
        <p>Your email has been successfully confirmed</p>
      </div>

      <div className="registration_footer">
        <Spin spinning={spinning}>
          <CustomButton type="primary" linkTo={LOGIN_PATH} onClick={() => setSpinning(true)}>
            Login
          </CustomButton>
        </Spin>
      </div>
    </div>
  )
}
