import * as React from 'react'
import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'

import { CONFIG_PATH } from 'constants/routes'

import { ConfigPage } from 'pages/config/ConfigPage'

interface Props {}

export const ConfigIndexComponent: React.FC<Props> = () => (
  <Switch>
    <Route path={`${CONFIG_PATH}/create`} render={() => <ConfigPage />} />
    <Route
      path={`${CONFIG_PATH}/:id/edit/:key`}
      render={(rp) => <ConfigPage id={+(rp.match.params.id as string)} activeKey={rp.match.params.key} />}
    />
    <Redirect from="/" to={`${CONFIG_PATH}/create`} />
  </Switch>
)
