import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import * as React from 'react'

import { CustomButton } from 'components/customButton/CustomButton'

interface Props {
  id: number
  handleDelete: (e: any) => void
}

const popconfirmText = 'Are you sure you want to delete this ad unit name?'

export const DeleteButtonPopConfirm: React.FC<Props> = ({ id, handleDelete }) => (
  <Popconfirm
    title={popconfirmText}
    okText="Yes"
    cancelText="No"
    onConfirm={() => {
      handleDelete(id)
    }}
  >
    <Tooltip title="Delete Ad Unit Name">
      <CustomButton type="warning" icon={<DeleteOutlined />}>
        Delete
      </CustomButton>
    </Tooltip>
  </Popconfirm>
)
