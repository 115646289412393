import { Environment } from 'env/Environment'

export const env: Environment = {
  apiUrl: 'https://api-qa.asteriobid.com',
  loginUrl: 'https://api-qa.asteriobid.com/api/login',
  logoutUrl: 'https://api-qa.asteriobid.com/api/logout',
  debug: false,
  debugConnectFrame: false,
  production: true,
  partnerInstallation: true,
  stripeApiKey: 'pk_live_Y2P3wvlokD69OqBt9UXaUtaD00JtFETjpO',
  library: 'testing',
}
