import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Alert, message, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useEffect, useState } from 'react'

import { accountApi } from 'api/accountApi'
import { ProfileForm } from 'api/base/api'

import { env } from 'env/index.ts'

import { CustomButton } from 'components/customButton/CustomButton'
import { Error } from 'components/globalError'

import { ProfileFormContainer } from 'pages/profile/ProfileFormContainer'
import { EmailConfirmationModal } from 'pages/registration'

import 'pages/profile/profile.scss'

interface Props {
  reloadProfile: () => void
}

const stripePromise = loadStripe(env.stripeApiKey)

export const ProfileScreen: React.FC<Props> = ({ reloadProfile }) => {
  const [loading, setLoading] = useState(false)
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false)

  const [profileForm, setProfileForm] = useState<ProfileForm | null>(null)

  const [errorMessage, setErrorMessage] = useState(
    'Profile update error. Please try again later. If the problem persists, please contact Customer Support.',
  )
  const [showError, setShowError] = useState(false)

  const alertMessage =
    'Please, fill out the fields below. We ask for your billing information to reduce the risk of fraud and to prevent the interruption in service if you decide to continue with AsterioBid after the trial ends. We’ll never charge you during your free trial, and you can cancel anytime.'

  const loadProfileEditorState = () => {
    accountApi.getProfileEditorState().then((response) => {
      if (response.form) {
        setProfileForm(response.form)
      }
      setShowConfirmEmailModal(response.form ? !response.form?.emailIsConfirmed : false)
    })
  }

  const handleResendConfirmationEmail = () => {
    accountApi.resendConfirmationEmail().then(message.success(`Confirmation email is sent to ${profileForm?.email}`))
  }

  useEffect(loadProfileEditorState, [])

  return (
    <>
      <Error showError={showError} message={errorMessage} closable isAuthorised />

      <EmailConfirmationModal
        modalVisible={showConfirmEmailModal}
        email={profileForm?.email}
        onCancel={() => setShowConfirmEmailModal(!showConfirmEmailModal)}
      />
      <div className="profile">
        <div className="profile__page">
          <Spin spinning={loading}>
            <Title className="profile__page_title" level={4}>
              Edit Profile
            </Title>
            {profileForm && !profileForm.cardEntered && (
              <>
                <br />
                <Alert type="info" message={alertMessage} />
              </>
            )}
            {profileForm && (
              <div className="profile__page_form">
                {!profileForm?.emailIsConfirmed && (
                  <div className="profile__page_alert">
                    <Alert
                      type="warning"
                      message="Email is not confirmed. Please check your inbox or"
                      action={
                        <CustomButton
                          style={{
                            marginLeft: '-11px',
                          }}
                          size="small"
                          type="link"
                          onClick={handleResendConfirmationEmail}
                        >
                          resend confirmation email
                        </CustomButton>
                      }
                    />
                  </div>
                )}
                {/* <ConfigProvider locale={en}> */}
                <Elements stripe={stripePromise}>
                  <ProfileFormContainer
                    profileForm={profileForm as ProfileForm}
                    setShowError={setShowError}
                    setErrorMessage={setErrorMessage}
                    reloadProfile={reloadProfile}
                    setLoading={setLoading}
                  />
                </Elements>
                {/* </ConfigProvider> */}
              </div>
            )}
          </Spin>
        </div>
      </div>
    </>
  )
}
