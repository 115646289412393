import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'

import { ADUNIT_PATH } from 'constants/routes'

import { AdUnitPage } from 'pages/adunit/AdUnitPage'

export const AdUnitIndexComponent = () => (
  <Switch>
    <Route path={`${ADUNIT_PATH}/create`} render={() => <AdUnitPage />} />
    <Route path={`${ADUNIT_PATH}/:id/edit/:key`} render={(rp) => <AdUnitPage id={+(rp.match.params.id as string)} />} />
    <Redirect from="/" to={`${ADUNIT_PATH}/create`} />
  </Switch>
)
