import {
  AlertOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  BarsOutlined,
  BranchesOutlined,
  ControlOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  DollarCircleOutlined,
  PictureOutlined,
  PieChartOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons'

import * as routes from 'constants/routes'

import { env } from 'env/index.ts'

type SidebarTab = {
  name: string
  key: string
  hasDropdown?: boolean
  button?: boolean
  content?: DropdownContent[]
  url?: string
  icon: typeof AreaChartOutlined
  isConfigTab?: boolean
  fullAccess?: boolean
}

type DropdownContent = {
  name: string
  url: string
  icon?: typeof AreaChartOutlined
}

export const dashboardTab: SidebarTab[] = [
  {
    name: 'Total Dashboard',
    key: routes.DASHBOARD_PATH.substring(1),
    url: routes.DASHBOARD_PATH,
    icon: BarChartOutlined,
  },
  {
    name: 'Alerts',
    key: routes.ALERT_PATH.substring(1),
    url: routes.ALERT_PATH,
    icon: AlertOutlined,
  },
]

export const detailedStatsTabs: SidebarTab[] = [
  {
    name: 'Bidder Analysis',
    key: `${routes.BIDDERS_STAT_PATH.substring(1)}/biddersList`,
    url: `${routes.BIDDERS_STAT_PATH}/biddersList`,
    icon: DollarCircleOutlined,
  },
  {
    name: 'Site Analysis',
    key: routes.SITES_STAT_PATH.substring(1),
    url: routes.SITES_STAT_PATH,
    icon: DesktopOutlined,
  },
  {
    name: 'UTM Analysis',
    key: routes.UTM_STAT_PATH.substring(1),
    url: routes.UTM_STAT_PATH,
    icon: PieChartOutlined,
  },
  {
    name: 'Media Type Analysis',
    key: routes.ADTYPE_STAT_PATH.substring(1),
    url: routes.ADTYPE_STAT_PATH,
    icon: PictureOutlined,
  },
  {
    name: 'Version Analysis',
    key: routes.VERSION_COMPARISON_STAT_PATH.substring(1),
    url: routes.VERSION_COMPARISON_STAT_PATH,
    icon: BranchesOutlined,
  },
]

export const reportTabs: SidebarTab[] = [
  {
    name: 'Generate Report',
    key: routes.REPORTS_PATH.substring(1),
    url: routes.REPORTS_PATH,
    icon: DatabaseOutlined,
  },
]

export const setupTabs: SidebarTab[] = [
  {
    name: 'Add New Site',
    key: `${routes.CONFIG_PATH.substring(1)}/create`,
    url: `${routes.CONFIG_PATH}/create`,
    icon: PlusOutlined,
    fullAccess: true,
  },
  {
    name: 'Sites',
    key: routes.SITES_LIST_PATH.substring(1),
    url: routes.SITES_LIST_PATH,
    icon: ControlOutlined,
  },
  {
    name: 'Ad Unit Dictionary',
    key: routes.ADUNIT_LIST_PATH.substring(1),
    url: routes.ADUNIT_LIST_PATH,
    icon: BarsOutlined,
  },
]

export const profileTabs: SidebarTab[] = env.partnerInstallation
  ? [
      {
        name: 'Edit Profile',
        key: routes.PROFILE_PATH.substring(1),
        url: routes.PROFILE_PATH,
        icon: UserOutlined,
      },
    ]
  : [
      {
        name: 'Edit Profile',
        key: routes.PROFILE_PATH.substring(1),
        url: routes.PROFILE_PATH,
        icon: UserOutlined,
      },
      {
        name: 'Payments',
        key: routes.PAYMENTS_PATH.substring(1),
        url: routes.PAYMENTS_PATH,
        icon: CreditCardOutlined,
      },
    ]
