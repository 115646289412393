import { ClientBlockBean, InfoControllerApi, ProfileControllerApi, ProfileForm } from 'api/base/api'
import { configuration } from 'api/utils/apiConfiguration'
import { customFetch } from 'api/utils/customFetch'
import { processGlobalError } from 'api/utils/processGlobalError'

import { env } from 'env/index'

import { loginStore } from 'store/LoginStore'

class AccountApi {
  api = new ProfileControllerApi(configuration, configuration.basePath, customFetch)

  infoApi = new InfoControllerApi(configuration, configuration.basePath, customFetch)

  login = (email: string, password: string) =>
    new Promise<string>((_, reject) => {
      const url = env.loginUrl
      const data = new URLSearchParams()
      data.append('username', email)
      data.append('password', password)
      fetch(url, {
        method: 'post',
        body: data,
        credentials: 'include',
      }).then(
        (response) => {
          if (response.ok) {
            response.text().then((token) => loginStore.login(token))
          } else {
            response.text().then(() => {
              reject('Incorrect username/password.')
            })
          }
        },
        () => {
          reject('Service is temporary unavailable.')
        },
      )
    })

  logout = () => {
    const url = env.logoutUrl
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: '',
    })
      .then(loginStore.logout)
      .catch((err) => processGlobalError(err))
  }

  checkUserInfo = () => this.infoApi.publisherIsLoggedIn()

  load() {
    return this.api.account()
  }

  update(form: ProfileForm) {
    return this.api.update1(form)
  }

  getProfileEditorState() {
    return this.api.getForEdit()
  }

  sendSupportRequest(issueType: string, description: string) {
    return this.api.sendContactSupportRequest({ issueType, description })
  }

  public resendConfirmationEmail() {
    return this.api.resendConfirmationEmail()
  }

  public loadPayments() {
    return this.api.payments()
  }

  public blockStatByClient(values: ClientBlockBean) {
    return this.api.blockStatByClient(values)
  }

  public reload() {
    return this.load().then((d) => d)
  }
}

export const accountApi = new AccountApi()
