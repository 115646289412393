import { CloseCircleOutlined } from '@ant-design/icons'
import { Result, Spin } from 'antd'
import * as React from 'react'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { RESET_PASSWORD_PATH } from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'

import 'pages/resetpassword/restore.scss'

export const ResetPasswordUnknownToken: React.FC = () => {
  const [spinning, setSpinning] = useState(false)
  const [goBackToReset, setGoBackToReset] = useState(false)

  const handleClick = async () => {
    await setSpinning(true)
    setGoBackToReset(true)
  }
  return goBackToReset ? (
    <Redirect to={RESET_PASSWORD_PATH} />
  ) : (
    <div className="reset-password">
      <Result
        icon={<CloseCircleOutlined style={{ color: '#f5222d' }} />}
        title="Reset password token is unknown"
        subTitle="Please request a new password reset link"
        extra={
          <Spin spinning={spinning}>
            <CustomButton type="primary" onClick={handleClick}>
              Request
            </CustomButton>
          </Spin>
        }
      />
    </div>
  )
}
