import { CheckOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { SiteBean } from 'api/base/api'

import { SITES_LIST_PATH } from 'constants/routes'

import { UserContext } from 'utils/userContext.tsx'

import { CustomButton } from 'components/customButton/CustomButton'

import { ArchiveButtonPopConfirm } from 'pages/config/ArchiveButtonPopConfirm'

import 'pages/config/configSiteForm.scss'

const { Option } = Select

interface Props {
  siteBean?: SiteBean
  showArchiveButton?: boolean
  handleSubmit: (form: any, protocol: string) => void
  handleArchive?: (e: any) => void
  submitButtonName: string
}

export const ConfigSiteForm: React.FC<Props> = ({
  siteBean,
  showArchiveButton,
  handleSubmit,
  handleArchive,
  submitButtonName,
}) => {
  const { fullAccess } = useContext(UserContext)

  const history = useHistory()

  const [form] = useForm()
  const formLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
      lg: { span: 4 },
      xl: { span: 3 },
      xxl: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 14 },
      lg: { span: 16 },
      xl: { span: 17 },
      xxl: { span: 18 },
    },
  }

  const [protocol, setProtocol] = useState('http://')
  const domainInitialValue = siteBean?.domain.value.includes('https://')
    ? siteBean.domain.value.substring(8)
    : siteBean?.domain.value.includes('http://')
    ? siteBean?.domain.value.substring(7)
    : siteBean?.domain.value

  const selectBefore = (
    <Select
      defaultValue={siteBean ? (siteBean.domain.value.includes('https://') ? 'https://' : 'http://') : 'http://'}
      className="select-before"
      onChange={setProtocol}
    >
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [siteBean])

  return (
    <div className="config-site-form__form">
      <Form
        form={form}
        {...formLayout}
        initialValues={{
          name: siteBean?.name.value,
          domain: domainInitialValue,
          description: siteBean?.description.value,
        }}
        onFinish={(form) => handleSubmit(form, protocol)}
      >
        <Form.Item
          name="name"
          label="Site Name"
          style={{ marginBottom: 16 }}
          rules={[
            {
              required: true,
              message: 'Please enter Site Name',
            },
          ]}
        >
          <Input placeholder="Site Name" />
        </Form.Item>

        <Form.Item
          name="domain"
          label="Domain"
          rules={[
            {
              required: true,
              message: 'Please enter Domain',
            },
          ]}
          style={{ marginBottom: 16 }}
        >
          <Input addonBefore={selectBefore} placeholder="domain.com" />
        </Form.Item>

        <Form.Item name="description" label="Description" style={{ marginBottom: 16 }}>
          <Input.TextArea placeholder="Notes" />
        </Form.Item>

        <Row align="stretch">
          <Col xs={12} sm={6} lg={4} xl={3} xxl={2} />
          <Col span={8}>
            <Space>
              <CustomButton disabled={!fullAccess} type={fullAccess ? 'primary' : 'disabled'} icon={<CheckOutlined />}>
                {submitButtonName}
              </CustomButton>
              <CustomButton type="bordered" onClick={() => history.push(SITES_LIST_PATH)}>
                Cancel
              </CustomButton>
            </Space>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {showArchiveButton && fullAccess && (
              <Space>
                <ArchiveButtonPopConfirm
                  handleArchive={handleArchive as (e: any) => void}
                  id={siteBean?.id as number}
                />
              </Space>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}
