import { Divider, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { LOGIN_PATH } from 'constants/routes'

import { CustomButton } from 'components/customButton/CustomButton'

import 'pages/resetpassword/restore.scss'

export const RestorePasswordEmailSentScreen: React.FC = () => {
  const [spinning, setSpinning] = useState(false)
  const [goToLogin, setGoToLogin] = useState(false)

  const handleClick = async () => {
    await setSpinning(true)
    setGoToLogin(true)
  }

  return goToLogin ? (
    <Redirect to={LOGIN_PATH} />
  ) : (
    <div className="reset-password">
      <div className="reset-password__header_title">
        <Title level={4}>Restore Password</Title>
      </div>

      <Divider />

      <div className="reset-password__content">
        Please check your inbox. Email with reset instructions was sent to the email your account was created with.
      </div>

      <div className="reset-password__footer">
        <Spin spinning={spinning}>
          <CustomButton type="primary" onClick={handleClick}>
            Login
          </CustomButton>
        </Spin>
      </div>
    </div>
  )
}
